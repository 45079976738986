import AppAPIInstance from 'utils/requestVinova';

import { API } from '../constans';

// GET LIST BIDDER
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDetailBidder: async (id: string): Promise<any> => {
    return AppAPIInstance.get(API.ADMINISTRATION.BIDDER.GET_DETAIL_BIDDER(id));
  },
  createBidder: async (paramsBidder: any): Promise<any> => {
    return AppAPIInstance.post(API.ADMINISTRATION.BIDDER.CREATE_BIDDER, paramsBidder);
  },
  updateBidder: async (id: string, params: any): Promise<any> => {
    return AppAPIInstance.put(API.ADMINISTRATION.BIDDER.UPDATE_BIDDER(id), params);
  },
  approveBidder: async (id: string, status: string, remarks?: string): Promise<any> => {
    return AppAPIInstance.patch(API.ADMINISTRATION.BIDDER.APPROVE_BIDDER(id), { status, remarks });
  },
  getNumberBidder: async (): Promise<any> => {
    return AppAPIInstance.get(API.ADMINISTRATION.BIDDER.GET_NUMBER_BIDDER);
  },
};
