/* eslint-disable consistent-return */
import { AssetsParse, TAsset, TAssetLog } from 'containers/Administration/Assets/Types';
import { BranchMaster } from 'utils/service/type';
import {
  GET_ALL_ASSETS_LOG_SUCCESS,
  GET_ALL_ASSETS_SUCCESS,
  GET_LIST_ASSETS_LOG_SUCCESS,
  GET_LIST_ASSETS_SUCCESS,
  GET_LIST_BRANCH_CODE_SUCCESS,
} from './action';

export type AssetsType = {
  assets: TAsset[];
  detailAsset: TAsset;
  assetsLog: TAssetLog[];
  assetsLogAll: TAssetLog[];
  detailAssetsLog: TAssetLog;
  assetsAll: AssetsParse[];
  branchMaster: BranchMaster[];
};

const defaultState: AssetsType = {
  assets: [],
  detailAsset: null,
  assetsLog: [],
  assetsLogAll: [],
  assetsAll: [],
  detailAssetsLog: null,
  branchMaster: [],
};

export default function assetsReducer(state = defaultState, action: any) {
  switch (action.type) {
    case GET_LIST_ASSETS_SUCCESS:
      return {
        ...state,
        assets: action.assets,
        metadata: action.metadata,
      };
    case GET_LIST_ASSETS_LOG_SUCCESS:
      return {
        ...state,
        assetsLog: action.assetsLog,
        metadata: action.metadata,
      };
    case GET_ALL_ASSETS_SUCCESS:
      return {
        ...state,
        assetsAll: action.assetsAll,
      };

    case GET_ALL_ASSETS_LOG_SUCCESS:
      return {
        ...state,
        assetsLogAll: action.assetsLogAll,
      };
    case GET_LIST_BRANCH_CODE_SUCCESS:
      return {
        ...state,
        branchMaster: action.branchMaster,
      };
    default:
      return state;
  }
}
