/* eslint-disable react/require-default-props */
import React, { CSSProperties, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Label, FormGroup } from 'reactstrap';
import { useFormikContext } from 'formik';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import styles from 'styled-components';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';
import { useSelector } from 'react-redux';
import { State } from 'redux/types';
import { NEPAL_TZ } from 'constant';
import NepaliDate from 'nepali-date';
import { addDays } from 'date-fns';

interface Props {
  id: string;
  label: string;
  labelStyle?: CSSProperties;
  inputIcon?: ReactElement;
  placeholder?: string;
  disabled?: boolean;
  maxDate?: any;
  minDate?: any;
}

const TvsDatepicker = ({ id, label, labelStyle, inputIcon, placeholder, disabled, maxDate, minDate }: Props) => {
  const { errors, touched, values, setValues } = useFormikContext<any>();

  const inputTouched = touched[id] || false;
  const inputErrors = errors[id] || false;
  const { auth } = useSelector((state: State) => state);
  const { t } = useTranslation();

  const onChangeDefault = (date: any) => {
    if (date?.adDate) {
      setValues({ ...values, [id]: moment(date.adDate, 'y-MM-DD').format('MM/DD/y') });
    } else {
      setValues({ ...values, [id]: moment(date).format('MM/DD/y') });
    }
  };

  const normalDatePicker = (
    <DatePickerStyle
      onChange={(date) => onChangeDefault(date)}
      id={id}
      maxDate={maxDate}
      minDate={minDate}
      selected={values[id] ? new Date(values[id]) : new Date()}
      dateFormat="dd/MM/y"
      placeholderText={t('Select Date')}
    />
  );

  const getDefaultDate = () => {
    if (values[id]) {
      return new NepaliDate(new Date(values[id])).format('YYYY-MM-DD');
    }

    if (maxDate === null || maxDate === undefined) {
      return new NepaliDate().format('YYYY-MM-DD');
    }

    return new NepaliDate(maxDate)?.format('YYYY-MM-DD');
  };

  const nepalDatePicker = () => {
    // const today = new Date();

    const min = minDate ? new NepaliDate(addDays(minDate, -1)) : null;
    const max = maxDate ? new NepaliDate(addDays(maxDate, 1)) : null;
    // const defaultValue = new NepaliDate(new Date(today.setDate(today.getDate() + 1)));
    return (
      <NepalWrapper>
        <Calendar
          onChange={onChangeDefault}
          language="en"
          style={{ width: '100%', height: '50px', padding: '0px 10px' }}
          dateFormat="DD/MM/YYYY"
          minDate={min?.format('YYYY-MM-DD')}
          maxDate={max?.format('YYYY-MM-DD')}
          disabled={disabled}
          // defaultDate={values[id] ? new NepaliDate(new Date(values[id])).format('YYYY-MM-DD') : null}
          defaultDate={getDefaultDate()}
          // hideDefaultValue={true}
        />
      </NepalWrapper>
    );
  };

  return (
    <FormGroup className="position-relative">
      {label && <Label style={labelStyle}>{label}</Label>}
      <Div className={disabled ? 'disabled' : ''}>
        {auth?.settings?.timezone === NEPAL_TZ ? nepalDatePicker() : normalDatePicker}
      </Div>
      <Icon>
        <img src={`${process.env.PUBLIC_URL}/images/calendar.png`} alt="calendar" width="16" height="18" />
      </Icon>
      {inputTouched && inputErrors && (
        <div className="form-text text-danger text-small">{inputTouched && inputErrors}</div>
      )}
    </FormGroup>
  );
};

const NepalWrapper = styles.div`
  position: relative;
  // &:after {
  //   content: 'Select Date';
  //   display: block;
  //   clear: both;
  //   position: absolute;
  //   inset: 0 0 0 0;
  //   border: 1px solid #ced4da;
  //   background: #fff;
  //   padding: 13px 10px;
  // }
`;

const Div = styles.div`
  &.disabled {
    position: relative;
    &:after {
      content: 'Select Date';
      clear: both;
      padding: 13px 10px;
      display: flex;
      position: absolute;
      inset: 0 0 0 0;
      background: #e5e3e3;
      border: 1px solid #ced4da;
    }
  }
`;
const DatePickerStyle = styles(DatePicker)`
  height: 50px;
  border: 1px solid #ced4da;
  width: 100%;
  padding: 10px;
  border-radius: 2px;
`;

const Icon = styles.div`
  position: absolute;
  top: 44px;
  bottom: 0;
  right: 10px;
`;
export default TvsDatepicker;
