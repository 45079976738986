import React from 'react';

const Alert = ({ size }) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size }}
    >
      <path
        d="M25 12.5C26.375 12.5 27.5 13.625 27.5 15V25C27.5 26.375 26.375 27.5 25 27.5C23.625 27.5 22.5 26.375 22.5 25V15C22.5 13.625 23.625 12.5 25 12.5ZM24.975 0C11.175 0 0 11.2 0 25C0 38.8 11.175 50 24.975 50C38.8 50 50 38.8 50 25C50 11.2 38.8 0 24.975 0ZM25 45C13.95 45 5 36.05 5 25C5 13.95 13.95 5 25 5C36.05 5 45 13.95 45 25C45 36.05 36.05 45 25 45ZM27.5 37.5H22.5V32.5H27.5V37.5Z"
        fill="#E15C5C"
      />
    </svg>
  );
};

export default Alert;
