import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label } from 'reactstrap';
import { Input, Select } from 'components/form';
import { OWNER_OF_PHONE_NUMBER } from 'constant';
import { MOBILE_COUNTRY_CODE } from 'constant/countrycode';
import { useFormikContext } from 'formik';

const ContactRecordPayment = () => {
  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useState<any>([]);
  const { values } = useFormikContext<any>();
  useEffect(() => {
    setCountryCode(
      MOBILE_COUNTRY_CODE.map((item) => ({
        label: `${item.value} ${item.label}`,
        value: item.value,
      })),
    );
  }, []);

  return (
    <div className="px-4 pt-2">
      <FormGroup row>
        <Col lg={6}>
          <Label>{t('Phone Number')}</Label>
          <FormGroup row className="p-0">
            <Col lg={4} className="pr-0">
              <Select
                label=""
                id="country_code"
                options={countryCode}
                placeholder="+00"
                disabled={values.is_not_provide_phone}
              />
            </Col>
            <Col lg={8} className="pl-1">
              <Input id="cust_phone" placeholder={t('Type phone number')} disabled={values.is_not_provide_phone} />
            </Col>
          </FormGroup>
        </Col>
        <Col lg={6}>
          <Select
            label={t('Owner of Phone Number')}
            placeholder={t('Select...')}
            id="phone_number_relationship"
            options={OWNER_OF_PHONE_NUMBER}
            disabled={values.is_not_provide_phone}
          />
        </Col>
        <Col lg={12}>
          <Input type="checkbox" id="is_not_provide_phone" label={t('Customer did not provide Phone Number')} inline />
        </Col>
      </FormGroup>
    </div>
  );
};

export default ContactRecordPayment;
