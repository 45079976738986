import { ACTIVE, SPACE } from 'constant/types';
import { get } from 'lodash/fp';
import moment from 'moment';

export type PrevUser = {
  id: number;
  first_name: string;
  last_name: string;
};

export type BranchMaster = {
  branchname: string;
};

export type UserAssets = { first_name: string; id: number; last_name: string; phone_number: string };
export type TAsset = {
  id: number;
  active: number;
  uuid: string;
  os: string;
  os_version: string;
  brand: string;
  model: string;
  app_version: string;
  push_token: string;
  user_id: number;
  prev_user_id: number;
  branch_code: number;
  sim_no: string;
  imei_1: string;
  imei_2: string;
  update_by: string;
  created_by: string;
  move_to_stock: boolean;
  createdAt: string;
  updatedAt: string;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    phone_number: string;
  };
  branch_master: { branchcode: string; branchname: string };
  branchcode: '5064538';
  branchname: 'Thamizhvanan V';
  prev_user: {
    id: number;
    first_name: string;
    last_name: string;
  };
  branch: {
    code: string;
    name: string;
  };
};
export interface IDevice {
  id: number;
  active: number;
  uuid: string;
  os: string;
  os_version: string;
  brand: string;
  model: string;
  app_version: string;
  push_token: null;
  user_id: number;
  prev_user_id: number;
  branch_code: string;
  sim_no: string;
  imei_1: string;
  imei_2: string;
  update_by: string;
  created_by: string;
  move_to_stock: null;
  createdAt: string;
  user: {
    phone_number: string;
  };
  updatedAt: string;
}
export type TAssetLog = {
  id: number;
  device_id: number;
  from: string;
  to: string;
  movement_status: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  device_type: string;
  phone_number: string;
  asset_id: number;
  device: IDevice;
};

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export const KeyAssets = {
  asset_id: 'Asset ID',
  owner_name: 'Owner Name',
  emp_id: 'Emp ID',
  mobile_number: 'Mobile Number',
  previous_owner: 'Previous Owner',
  previous_emp_id: 'Previous Emp ID',
  branch_code: 'Branch Code',
  branch_name: 'Branch Name',
  status: 'Status',
  os: 'OS',
  os_version: 'OS Version',
  brand: 'Brand',
  model: 'Model',
  sim_no: 'SIM No.',
  imei_1: 'IMEI1',
  imei_2: 'IMEI2',
  app_version: 'App Version',
  last_updated: 'Last Updated',
  updated_by: 'Updated By',
  created_by: 'Created By',
  created_date: 'Create Date',
};

export const headerTable = [
  'Asset ID',
  'Owner Name',
  'Emp ID',
  'Mobile Number',
  'Previous Owner',
  'Previous Emp ID',
  'Branch Code',
  'Branch Name',
  'Status',
  'OS',
  'OS Version',
  'Brand',
  'Model',
  'SIM No.',
  'IMEI1',
  'IMEI2',
  'App Version',
  'Last Updated',
  'Updated By',
  'Created By',
  'Create Date',
];

export const headerTableAssetLog = {
  asset_type: 'Asset Type',
  model: 'Model',
  sim_No: 'Sim No.',
  imei1: 'IMEI1',
  imei2: 'IMEI2',
  mobile_number: 'Mobile Number',
  asset_id: 'Asset Id',
  from: 'From',
  to: 'To',
  date: 'Date',
  movement_status: 'Movement Status',
};

export const keyTableAssetsLog = {
  id: 'id',
  device_id: 'device_id',
  from: 'from',
  to: 'to',
  movement_status: 'movement_status',
  createdBy: 'createdBy',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  device_type: 'device_type',
  asset_type: 'asset_type',
  mobile_number: 'mobile_number',
  asset_id: 'asset_id',
  device: {
    id: 'device.id',
    active: 'device.active',
    uuid: 'device.uuid',
    os: 'device.os',
    os_version: 'device.os_version',
    brand: 'device.brand',
    model: 'device.model',
    app_version: 'device.app_version',
    push_token: 'device.push_token',
    user_id: 'device.user_id',
    prev_user_id: 'device.prev_user_id',
    branch_code: 'device.branch_code',
    sim_no: 'device.sim_no',
    imei_1: 'device.imei_1',
    imei_2: 'device.imei_2',
    update_by: 'device.update_by',
    created_by: 'device.created_by',
    move_to_stock: 'device.move_to_stock',
    createdAt: 'device.createdAt',
    updatedAt: 'device.updatedAt',
    user: {
      phone_number: 'device.user.phone_number',
    },
  },
};

export type AssetsParse = {
  uuid?: number;
  employLastName?: string;
  employFirstName?: string;
  empId?: number;
  phoneNumber?: string;
  prevFirstEmp?: string;
  prevLastEmp?: string;
  prevId?: number;
  branchCode?: string;
  branchName?: string;
  status?: string;
  os?: string;
  osVersion?: string;
  brand?: string;
  model?: string;
  simNo?: string;
  imei1?: string;
  imei2?: string;
  appVersion?: string;
  lastUpdate?: string;
  updateBy?: string;
  createBy?: string;
  createDate?: string;
};

export function parseAssets(data: any): AssetsParse {
  const uuid = get('uuid', data);
  const employLastName = get('user.last_name', data);
  const employFirstName = get('user.first_name', data);
  const empId = get('user_id', data);
  const phoneNumber = get('user.phone_number', data);
  const prevFirstEmp = get('prev_user.first_name', data);
  const prevLastEmp = get('prev_user.last_name', data);
  const prevId = get('prev_user_id', data);
  const branchCode = get('branch_master.branchcode', data);
  const branchName = get('branch_master.branchname', data);
  const status = get('active', data);
  const os = get('os', data);
  const osVersion = get('os_version', data);
  const brand = get('brand', data);
  const model = get('model', data);
  const simNo = get('sim_no', data);
  const imei1 = get('imei_1', data);
  const imei2 = get('imei_2', data);
  const appVersion = get('app_version', data);
  const updateBy = get('update_by', data);
  const createBy = get('created_by', data);
  const updatedAt = get('updatedAt', data);
  const createdAt = get('createdAt', data);
  const lastUpdate = moment(updatedAt).format('DD/MM/YYYY h:mm A');
  const createDate = moment(createdAt).format('DD/MM/YYYY h:mm A');
  return {
    uuid,
    employLastName,
    employFirstName,
    empId,
    phoneNumber,
    prevFirstEmp,
    prevLastEmp,
    prevId,
    branchCode,
    branchName,
    status,
    os,
    osVersion,
    brand,
    model,
    simNo,
    imei1,
    imei2,
    appVersion,
    lastUpdate,
    updateBy,
    createBy,
    createDate,
  };
}

export type AssetsLogParse = {
  assetType: string;
  uuid: number;
  model: string;
  imei1: string;
  imei2: string;
  from: string;
  to: string;
  createdAt: string;
  movementStatus: string;
  simNo: string;
  phoneNumber: string;
};
export function parseAssetLog(data: any): AssetsLogParse {
  const assetType = get('device.os', data);
  const model = get('device.model', data);
  const simNo = get('device.sim_no', data);
  const imei1 = get('device.imei_1', data);
  const imei2 = get('device.imei_2', data);
  const phoneNumber = get('device.user.phone_number', data);
  const uuid = get('device.uuid', data);
  const from = get('from', data);
  const to = get('to', data);
  const date = get('createdAt', data);
  const movementStatus = get('movement_status', data);
  const createdAt = moment(date).format('DD/MM/YYYY h:mm A');
  return {
    assetType,
    uuid,
    model,
    simNo,
    phoneNumber,
    imei1,
    imei2,
    createdAt,
    from,
    to,
    movementStatus,
  };
}

export const excelHeaderAssetsLog = [
  { header: headerTableAssetLog.asset_type, key: 'assetType' },
  { header: headerTableAssetLog.model, key: 'model' },
  { header: headerTableAssetLog.sim_No, key: 'simNo' },
  { header: headerTableAssetLog.imei1, key: 'imei1' },
  { header: headerTableAssetLog.imei2, key: 'imei2' },
  { header: headerTableAssetLog.mobile_number, key: 'phoneNumber' },
  { header: headerTableAssetLog.asset_id, key: 'uuid' },
  { header: headerTableAssetLog.from, key: 'from' },
  { header: headerTableAssetLog.to, key: 'to' },
  { header: headerTableAssetLog.date, key: 'createdAt' },
  { header: headerTableAssetLog.movement_status, key: 'movementStatus' },
];

export const excelHeaderAssets = [
  { header: headerTable[0], key: 'uuid' },
  { header: headerTable[1], key: 'employFirstName' },
  { header: headerTable[2], key: 'empId' },
  { header: headerTable[3], key: 'phoneNumber' },
  { header: headerTable[4], key: 'prevFirstEmp' },
  { header: headerTable[5], key: 'prevId' },
  { header: headerTable[6], key: 'branchCode' },
  { header: headerTable[7], key: 'branchName' },
  { header: headerTable[8], key: 'status' },
  { header: headerTable[9], key: 'os' },
  { header: headerTable[10], key: 'osVersion' },
  { header: headerTable[11], key: 'brand' },
  { header: headerTable[12], key: 'model' },
  { header: headerTable[13], key: 'simNo' },
  { header: headerTable[14], key: 'imei1' },
  { header: headerTable[15], key: 'imei2' },
  { header: headerTable[16], key: 'appVersion' },
  { header: headerTable[17], key: 'lastUpdate' },
  { header: headerTable[18], key: 'updateBy' },
  { header: headerTable[19], key: 'createBy' },
  { header: headerTable[20], key: 'createDate' },
];
