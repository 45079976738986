// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Localization } from 'constant/config';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const analyticsEnabled = process.env.REACT_APP_ANALYTICS_ENABLED === 'true';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: Localization.apiKey,
  authDomain: 'aidc-f9327.firebaseapp.com',
  projectId: 'aidc-f9327',
  storageBucket: 'aidc-f9327.appspot.com',
  messagingSenderId: '834347563222',
  appId: Localization.appId,
  measurementId: Localization.measurementId,
};

const EVENT_TYPE = {
  general: {
    appLoad: 'APP_LOADED',
    userLoginVisited: 'USER_LOGIN_VISITED',
    customerLoginVisited: 'CUSTOMER_LOGIN_VISITED',
    forgotPasswordVisited: 'FORGOT_PASSWORD_VISITED',
    resetPasswordVisited: 'RESET_PASSWORD_VISITED',
  },
  cashier: {
    dashboardVisited: 'CASHIER_DASHBOARD_VISITED',
    recordMemoVisited: 'CASHER_RECORD_MEMO_VISITED',
    recordPTPVisited: 'CASHIER_RECORD_PTP_VISITED',
    recordPaymentVisited: 'CASHIER_RECORD_PAYMENT_VISITED',
    transactionListingVisited: 'CASHIER_TRANSACTION_LISTING_VISITED',
    transactionListingExported: 'CASHIER_TRANSACTION_LISTING_EXPORTED',
    transactionDetailsVisited: 'CASHIER_TRANSACTION_DETAILS_VISITED',
  },
  manager: {
    dashboardVisited: 'MANAGER_DASHBOARD_VISITED',
    dailyCollectionVisited: 'MANAGER_DAILY_COLLECTION_VISITED',
    dailyCollectionDetailVisited: 'MANAGER_DAILY_COLLECTION_DETAIL_VISITED',
    reportsVisited: 'MANAGER_REPORTS_VISITED',
    dailyCollectionReportDownloaded: 'MANAGER_DAILY_COLLECTION_REPORT_DOWNLOADED',
    summaryReportDownloaded: 'MANAGER_SUMMARY_REPORT_DOWNLOADED',
    branchListingVisited: 'MANAGER_BRANCH_LISTING_VISITED',
    scrollListingVisited: 'MANAGER_SCROLL_LISTING_VISITED',
    collectionSpecialistListingVisited: 'MANAGER_COLLECTION_SPECIALIST_LISTING_VISITED',
    collectionSpecialistDetailVisited: 'MANAGER_COLLECTION_SPECIALIST_DETAIL_VISITED',
    cashierListingVisited: 'MANAGER_CASHIER_LISTING_VISITED',
    cashierDetailVisited: 'MANAGER_CASHIER_DETAIL_VISITED',
    customerListingVisited: 'MANAGER_CUSTOMER_LISTING_VISITED',
    customerAccountDetailVisited: 'MANAGER_CUSTOMER_ACCOUNT_DETAIL_VISITED',
  },
  borrower: {
    dashboardVisited: 'BORROWER_DASHBOARD_VISITED',
    accountDetailVisited: 'BORROWER_ACCOUNT_DETAIL_VISITED',
  },
  dealer: {
    activeAccountListVisited: 'DEALER_ACTIVE_ACCOUNT_LIST_VISITED',
    maturedAccountListVisited: 'DEALER_MATURED_ACCOUNT_LIST_VISITED',
    activeAccountDetailVisited: 'DEALER_ACTIVE_ACCOUNT_DETAIL_VISITED',
    maturedAccountDetailVisited: 'DEALER_MATURED_ACCOUNT_DETAIL_VISITED',
  },
};

const PAGES = {
  general: {
    userLoginPage: 'USER_LOGIN_PAGE',
    customerLoginPage: 'CUSTOMER_LOGIN_PAGE',
    forgotPasswordPage: 'FORGOT_PASSWORD_PAGE',
    resetPasswordPage: 'RESET_PASSWORD_PAGE',
  },
  cashier: {
    dashboardPage: 'CASHIER_DASHBOARD_PAGE',
    recordMemoPage: 'CASHIER_RECORD_MEMO_PAGE',
    recordPTPPage: 'CASHIER_RECORD_PTP_PAGE',
    recordPaymentPage: 'CASHIER_RECORD_PAYMENT_PAGE',
    transactionListingPage: 'CASHIER_TRANSACTION_LISTING_PAGE',
    transactionDetailsPage: 'CASHIER_TRANSACTION_DETAILS_PAGE',
  },
  manager: {
    dashboardPage: 'MANAGER_DASHBOARD_PAGE',
    dailyCollectionPage: 'MANAGER_DAILY_COLLECTION_PAGE',
    dailyCollectionDetailPage: 'MANAGER_DAILY_COLLECTION_DETAIL_PAGE',
    reportsPage: 'MANAGER_REPORTS_PAGE',
    branchListingPage: 'MANAGER_BRANCH_LISTING_PAGE',
    collectionSpecialistListingPage: 'MANAGER_COLLECTION_SPECIALIST_LISTING_PAGE',
    collectionSpecialistDetailPage: 'MANAGER_COLLECTION_SPECIALIST_DETAIL_PAGE',
    cashierListingPage: 'MANAGER_CASHIER_LISTING_PAGE',
    cashierDetailPage: 'MANAGER_CASHIER_DETAIL_PAGE',
    customerListingPage: 'MANAGER_CUSTOMER_LISTING_PAGE',
    customerAccountDetailPage: 'MANAGER_CUSTOMER_ACCOUNT_DETAIL_PAGE',
  },
  borrower: {
    dashboardPage: 'BORROWER_DASHBOARD_PAGE',
    accountDetailPage: 'BORROWER_ACCOUNT_DETAIL_PAGE',
  },
  dealer: {
    activeAccountListPage: 'DEALER_ACTIVE_ACCOUNT_LIST_PAGE',
    maturedAccountListPage: 'DEALER_MATURED_ACCOUNT_LIST_PAGE',
    activeAccountDetailPage: 'DEALER_ACTIVE_ACCOUNT_DETAIL_PAGE',
    maturedAccountDetailPage: 'DEALER_MATURED_ACCOUNT_DETAIL_PAGE',
  },
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const logAnalyticsEvent = (eventType: string, eventParams: any, pageParams: any) => {
  // log action event
  if (eventType) {
    logEvent(analytics, eventType, eventParams);
  }

  // update page view
  if (pageParams.page_title) {
    logEvent(analytics, 'page_view', pageParams);
  }
};

const Analytics = {
  analyticsEnabled,
  EVENT_TYPE,
  PAGES,
  logAnalyticsEvent,
};

export default Analytics;
