/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Select } from 'components/form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Localization } from 'constant/config';
import { Col } from 'reactstrap';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { State } from 'redux/types';
import { toastr } from 'react-redux-toastr';

const GOOGLE_API = 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyC0CkMXNktfIiisGZgMtkGQLPrF7Sg2K4M';
const COUNTRY = Localization.country;

const AddressGroupField = ({ col, disabled }: any) => {
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext<any>();

  const { crudState } = useSelector((st: State) => st);

  const [provinces, setProvinces] = useState<any>({
    currentKey: '',
    current: values.province,
    loading: false,
    data: [],
  });

  const [cities, setCities] = useState<any>({
    loading: false,
    data: [],
  });

  const [zipcodes, setZipcodes] = useState<any>({
    loading: false,
    data: [],
  });

  useEffect(() => {
    if (crudState?.startup?.byId?.payload) {
      const updatedProvinces = { ...provinces };
      const updatedCity = { ...cities };
      const { pending, payload, success, error } = crudState?.startup?.byId;
      if (success) {
        const { cities: ct, provinces: pv, zipcodes: zc } = payload;
        const currentProvince: any = pv?.find((it: any) => it.name === values.province);

        // get province list
        Object.assign(updatedProvinces, {
          loading: pending,
          data:
            pv.map((item: any) => ({
              id: item.name,
              label: item.name,
              value: item.name,
              key: item.key,
            })) || [],
          current: currentProvince,
          currentKey: currentProvince?.key,
        });
        setProvinces(updatedProvinces);

        // get city list
        Object.assign(updatedCity, {
          loading: pending,
          data: ct.map((item: any) => ({
            id: item.key,
            label: item.key,
            value: item.key,
            province: item.province,
            city: item.city,
          })),
        });
        setCities(updatedCity);

        setZipcodes({
          loading: false,
          data: zc,
        });
      } else {
        toastr.error('Unable get startup data', typeof error === 'string' ? 'error' : '');
      }
    }
  }, [crudState?.startup]);

  useEffect(() => {
    const findProvince = provinces.data.find((item: any) => item.label === values.province);

    if (findProvince) {
      setProvinces({
        ...provinces,
        currentKey: findProvince.key,
        current: findProvince,
      });
    }
  }, [values.province]);

  useEffect(() => {
    handleChange(values.zipcode);
  }, [values.zipcode]);

  const handleChange = (value: any) => {
    if (value.length === Localization.zipcodeLength) {
      onSearchZipcode(value);
    }
  };

  const onSearchZipcode = async (value: any) => {
    setCities({
      ...cities,
      loading: true,
    });
    setProvinces({
      ...provinces,
      loading: true,
    });
    let currentProvince: any = {};
    let data = await getLocationFromGglMap(value, cities, provinces);

    if (!data) {
      data = await getCityFromApi(value, cities.data, provinces.data, zipcodes.data);
    }

    if (data) {
      setValues({
        ...values,
        zipcode: data.zipcode,
        city: data.city,
        province: data.province,
      });
      currentProvince = provinces.data.find((item: any) => item.label === data?.province);
    } else {
      setValues({
        ...values,
        zipcode: value,
        city: '',
        province: '',
      });
      toastr.error('', t('Unable to retrieve city. Please key in manually or retry again.'));
    }

    setCities({
      ...cities,
      loading: false,
    });
    setProvinces({
      ...provinces,
      loading: false,
      current: currentProvince || {},
      currentKey: currentProvince?.key || '',
    });
  };

  const onChangeCity = async (value: any) => {
    const pv = provinces.data.find((item: any) => item.key === value.province);
    const updatedValues = { ...values, province: pv.label, city: value.label };
    let data = await getLocationFromGglMap(
      `${values.province}, ${value.label} ${COUNTRY}`,
      cities,
      provinces,
      'postal',
    );

    if (!data) {
      data = getZipcodeFromApi(value.label, zipcodes.data, pv.label);
    }

    if (data) {
      updatedValues.zipcode = data;
    } else {
      updatedValues.zipcode = '';
      toastr.error('', t('Unable to retrieve zipcode. Please key in manually or retry again.'));
    }
    setValues(updatedValues);
  };

  const onChangeProvince = (value: any) => {
    setValues({
      ...values,
      zipcode: '',
      province: value.value,
      city: '',
    });
  };

  return (
    <>
      <Col lg={col || 6}>
        <Input id="zipcode" label={t('Zipcode/Postcode')} handleChange={handleChange} disabled={disabled} />
      </Col>
      <Col lg={col || 6}>
        <Select
          id="province"
          placeholder={t('Select...')}
          label={t('Province')}
          options={provinces?.data}
          loading={provinces.loading}
          handleChange={onChangeProvince}
          disabled={disabled}
        />
      </Col>
      {cities.data && (
        <Col lg={col || 6}>
          <Select
            id="city"
            placeholder={t('Select...')}
            label={t('City')}
            options={cities?.data.filter((it: any) => it.province === provinces.currentKey)}
            loading={cities.loading}
            handleChange={onChangeCity}
            disabled={disabled}
          />
          {values.city && cities.data.findIndex((it: any) => it.label === values.city) === -1 && (
            <div className="text-danger text-small" style={{ marginTop: '-10px', marginBottom: '10px' }}>
              Current city is '{values.city}' doesn't map with any options in our database. Please select an option in
              the select box.
            </div>
          )}
        </Col>
      )}
    </>
  );
};

const getLocationFromGglMap = async (value: string, cities: any, provinces: any, type = 'address') => {
  const request = await fetch(
    `${GOOGLE_API}&address=${value},${COUNTRY}&components=country:${Localization.countryAbbrev}`,
  );
  const response = await request.text();
  const { results } = await JSON.parse(response);
  if (results && results[0] && results[0].address_components) {
    const findCity = results[0].address_components.find((item: any) => findAddressType(type, item));

    if (findCity) {
      const cityDetail = cities.data.find((item: any) => item.id === findCity.long_name);
      if (cityDetail) {
        const pv = provinces.data.find((item: any) => item.key === cityDetail.province);

        if (pv) {
          return {
            zipcode: value,
            city: findCity?.long_name ? findCity.long_name.replace(' City', '') : '',
            province: pv.label,
          };
        }
      }
    }
  }
  return null;
};

const findAddressType = (type: string, item: any) => {
  switch (type) {
    case 'address':
      return item.types.indexOf('locality') > -1 && item.types.indexOf('political');
    default:
      return item.types.indexOf('postal') > -1;
  }
};

const getCityFromApi = (value: string, cities: any, provinces: any, zipcodes: any) => {
  const currentZipcode = zipcodes[+value] ? zipcodes[+value] : null;
  let city = '';
  let province = '';
  if (currentZipcode) {
    const mapping = currentZipcode.map((item: string) => item.replace(' City', ''));
    const cityMapping = cities.find((item: any) => mapping.indexOf(item.label) > -1);

    const provinceMapping = provinces.find((item: any) => mapping.indexOf(item.label) > -1);

    if (cityMapping) {
      city = cityMapping.label;
      if (provinceMapping) {
        province = provinceMapping.label;
      } else {
        const pv = provinces.find((item: any) => item.key === cityMapping.province);
        if (pv) {
          province = pv.label;
        }
      }

      return {
        zipcode: value,
        city,
        province,
      };
    }
  }
  return null;
};

const getZipcodeFromApi = (value: string, zipcodes: any, province: string) => {
  let zc: any = null;

  const search: any = [];
  Object.keys(zipcodes).forEach((key) => {
    if (zc) return;
    if (typeof zipcodes[key] === 'string') {
      if (zipcodes[key] === value) zc = key;
    } else {
      const mapping = zipcodes[key].filter((it: any) => it === value);
      if (mapping.length > 0)
        search.push({
          key,
          data: zipcodes[key],
        });
    }
  });

  const mappingProvince = search.find((item: any) => item?.data.indexOf(province) > -1);

  if (mappingProvince) {
    zc = mappingProvince.key;
  } else if (search) {
    zc = search[0]?.key?.length < Localization.zipcodeLength ? `0${search[0]?.key}` : search[0]?.key;
  }
  return zc;
};

export default AddressGroupField;
