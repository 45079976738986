import { get } from 'lodash/fp';

export type StateBase = {
  limit?: number;
  page?: number;
  search?: string;
};

export type BranchMaster = {
  name: string;
  code: number;
};

export function parseBranchMaster(data: any): BranchMaster {
  const name = get('branchname', data);
  const code = get('branchcode', data);
  return {
    name,
    code,
  };
}
