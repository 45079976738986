import AppAPIInstance from 'utils/requestVinova';
import { StateHistory } from 'redux/types';
import { API } from '../constans';
import { TStateAgencyUser } from './type';

export const postSendSMSAgencyUser = async (payload): Promise<any> => {
  return AppAPIInstance.post(API.ADMINISTRATION.AGENCY_USER.SEND_SMS, payload);
};
// GET LIST BIDDER
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getListAgencyUser: async (state: TStateAgencyUser): Promise<any> => {
    return AppAPIInstance.get(API.ADMINISTRATION.AGENCY_USER.GET_LIST_AGENCY_USER(state));
  },
  uploadAgencyUser: async (payload, type): Promise<any> => {
    return AppAPIInstance.post(API.ADMINISTRATION.AGENCY.UPLOAD, {
      file_uploaded_url: payload,
      type,
    });
  },
  getAgencyUserHistory: async (payload: StateHistory): Promise<any> => {
    return AppAPIInstance.get(API.ADMINISTRATION.BOOK.HISTORY, { params: payload });
  },
  sendSMS: async (data): Promise<any> => {
    return AppAPIInstance.post(API.ADMINISTRATION.AGENCY_USER.SEND_SMS, data);
  },
  getDetailAgencyUser: async (id): Promise<any> => {
    return AppAPIInstance.get(`${API.ADMINISTRATION.AGENCY_USER.GET_DETAIL}/${id}`);
  },
  createAgencyUser: async (payload): Promise<any> => {
    return AppAPIInstance.post(API.ADMINISTRATION.AGENCY_USER.CREATE, payload);
  },
  putDetailAgencyUser: async (data, id): Promise<any> => {
    return AppAPIInstance.put(API.ADMINISTRATION.AGENCY_USER.EDIT(id), data);
  },
};
