/* eslint-disable consistent-return */
import { MetaData } from 'constant/types';
import { TAgencyUser } from 'containers/Administration/AgencyUser/type';
import { GET_LIST_AGENCY_USER_SUCCESS } from './action';
import { CrudActionAgencyUser } from './types';

export type RoleType = {
  metadata?: MetaData;
  listAgencyUser?: TAgencyUser;
};

const defaultState: RoleType = {
  listAgencyUser: null,
};

export default function agencyUserReducer(state = defaultState, action: CrudActionAgencyUser) {
  switch (action.type) {
    case GET_LIST_AGENCY_USER_SUCCESS:
      return {
        ...state,
        listAgencyUser: action.payload.listAgencyUser,
      };
    default:
      return state;
  }
}
