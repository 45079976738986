import React from 'react';

function FilterIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" version="1" viewBox="0 0 372 356">
      <path
        d="M2374 3190c-27-11-74-67-74-88v-342c1-357 0-352 63-390 65-40 158-15 193 51 12 23 16 55 15 114-1 58 2 85 12 93 9 8 87 11 240 11 211-1 229 1 259 20 42 25 57 51 64 104 9 71-42 137-117 151-13 2-122 5-242 5l-217 1 1 89c1 101-13 140-64 171-33 21-96 25-133 10zM683 2910c-98-23-139-120-88-207 39-67 12-64 673-64 328 0 627 0 665 1h67v280l-642-1c-354-1-658-4-675-9zM1213 2320c-52-31-67-72-68-177v-93H905c-270 0-286-4-318-73-22-46-21-79 3-127 38-74 69-79 486-80h62l4-87c3-57 11-100 22-121 48-92 184-95 243-5 16 25 18 56 18 358s-2 333-18 358c-42 64-131 86-194 47zM2367 2052l-648-2 3-138 3-137h655c711 0 700-1 740 55 60 84 14 203-85 221-11 2-312 3-668 1zM1765 1439l-40-38-2-336c-3-305-1-340 15-367 37-65 133-89 198-49 47 29 64 75 64 175v86l503 1c276 0 512 2 525 5 37 7 81 33 96 57 42 65 22 159-43 199l-36 23-522 3-521 3-4 100-3 100-43 40c-39 37-46 40-95 38-44-2-57-8-92-40zM680 1191c-45-14-71-35-92-75-40-78 2-171 88-195 25-7 168-11 397-11h357v290l-362-1c-200 0-374-4-388-8z"
        transform="matrix(.1 0 0 -.1 0 356)"
      />
    </svg>
  );
}

export default FilterIcon;
