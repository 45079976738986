/* eslint-disable import/no-cycle */
import { ListHistory, MetaData } from 'constant/types';
import { StateHistory } from 'redux/types';

import { CrudActionHistory } from './types';

export const UPDATE_PANIGATION = 'UPDATE_PANIGATION';
export const updatePanigation = (payload: MetaData) => {
  return {
    type: UPDATE_PANIGATION,
    payload,
  };
};

export const SAVE_PANIGATION = 'SAVE_PANIGATION';
export const savePanigation = (metadata: MetaData) => {
  return {
    type: SAVE_PANIGATION,
    metadata,
  };
};

export const GET_HISTORY = 'GET_HISTORY';
export const getHistory = (stateHistory: StateHistory): CrudActionHistory => {
  return {
    type: 'GET_HISTORY',
    payload: {
      stateHistory,
    },
  };
};

export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const getHistorySuccess = (listHistory: ListHistory[]): CrudActionHistory => {
  return {
    type: 'GET_HISTORY_SUCCESS',
    payload: {
      listHistory,
    },
  };
};
