/* eslint-disable no-unused-vars */
import { Effect, call, put, select, takeLatest } from 'redux-saga/effects';
import assetsApi from 'utils/service/AssetAPI/assetsApi';
import { toastr } from 'react-redux-toastr';
import { MetaData } from 'constant/types';
import { parseAssetLog, parseAssets } from 'containers/Administration/Assets/Types';
import { parseBranchMaster } from 'utils/service/type';
import { updatePanigation } from 'redux/application/action';
import {
  GET_ALL_ASSETS,
  GET_ALL_ASSETS_LOG,
  GET_LIST_ASSETS,
  GET_LIST_ASSETS_LOG,
  GET_LIST_BRANCH_CODE,
  getAllAssetLogSuccess,
  getAllAssetSuccess,
  getListAssetsLogSuccess,
  getListAssetsSucess,
  getListBranchCodeSucess,
} from './action';
import { CrudAction, CrudActionAssetsLog, CrudActionBranchCode } from './types';

function* getListAssetsSaga(action: CrudAction): Generator<Effect, void, any> {
  const stateTable = action.payload;
  try {
    const res = yield call(assetsApi.getListAssets, stateTable);
    const { data } = res;
    const { metadata }: { metadata: MetaData } = data;
    yield put(updatePanigation(metadata));
    if (data) {
      yield put(getListAssetsSucess(data?.data));
    }
  } catch (error) {
    toastr.error('', 'get list assets fail', { timeOut: 3000 });
  }
}

function* getAllAssetsSaga(action: CrudAction): Generator<Effect, void, any> {
  const stateList = action.payload;
  try {
    const res = yield call(assetsApi.getAllAssets, stateList);
    const { data } = res;
    const parseAssetsResponse = data.data.map((item) => parseAssets(item));
    if (parseAssetsResponse) {
      yield put(getAllAssetSuccess(parseAssetsResponse));
    }
  } catch (error) {
    toastr.error('', 'get list assets fail', { timeOut: 3000 });
  }
}

function* getAllAssetsLogSaga(action: CrudActionAssetsLog): Generator<Effect, void, any> {
  const stateList = action.payload;
  try {
    const res = yield call(assetsApi.getAllAssetsLog, stateList);
    const { data } = res;
    const parseAssetsResponse = data.data.rows.map((item) => parseAssetLog(item));
    if (parseAssetsResponse) {
      yield put(getAllAssetLogSuccess(parseAssetsResponse));
    }
  } catch (error) {
    toastr.error('', 'get list assets fail', { timeOut: 3000 });
  }
}

function* getListAssetsLogSaga(action: CrudActionAssetsLog): Generator<Effect, void, any> {
  const stateTable = action.payload;
  try {
    const res = yield call(assetsApi.getListAssetsLog, stateTable);
    const { data } = res;
    const { meta } = data;
    yield put(updatePanigation(meta));
    if (data) {
      yield put(getListAssetsLogSuccess(data?.data?.rows));
    }
  } catch (error) {
    toastr.error('', 'get list assets log fail', { timeOut: 3000 });
  }
}

function* getListBranchCode(action: CrudActionBranchCode): Generator<Effect, void, any> {
  const stateBase = action.payload;
  try {
    const res = yield call(assetsApi.getBranchMaster, stateBase);
    const {
      data: { meta, branchMasters },
    } = res;
    yield put(updatePanigation(meta));
    const responseParse = branchMasters.rows.map((item) => parseBranchMaster(item));
    if (branchMasters) {
      yield put(getListBranchCodeSucess(responseParse));
    }
  } catch (error) {
    toastr.error('', 'get list branch code fail', { timeOut: 3000 });
  }
}

function* assetsSaga() {
  yield takeLatest(GET_LIST_ASSETS, getListAssetsSaga);
  yield takeLatest(GET_LIST_ASSETS_LOG, getListAssetsLogSaga);
  yield takeLatest(GET_ALL_ASSETS, getAllAssetsSaga);
  yield takeLatest(GET_ALL_ASSETS_LOG, getAllAssetsLogSaga);
  yield takeLatest(GET_LIST_BRANCH_CODE, getListBranchCode);
}

export default assetsSaga;
