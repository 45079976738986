import React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import { Localization } from 'constant';

const Footer = () => (
  <div
    style={{
      position: 'fixed',
      bottom: 0,
      height: '90px',
      background: '#fff',
      borderTop: '1px solid #DAE0F7',
      width: '100%',
      padding: '20px 0',
      zIndex: 10,
    }}
  >
    <Container className="text-center d-flex justify-content-center align-items-center h-100">
      <div className="mt-3">
        <img src={`${process.env.PUBLIC_URL}/images/powered_by.png`} alt="powered by tvs" />
        <Hrline />
        <div style={{ width: 300 }} className="d-flex justify-content-around mb-3 ml-3 mr-3">
          <LegalLink href={Localization.PRIVACY_POLICY_URL} target="_blank">
            Privacy Policy
          </LegalLink>
          <LegalLink href={Localization.TERMS_OF_USE_URL} target="_blank">
            Terms of use
          </LegalLink>
          <LegalLink href={Localization.COOKIE_POLICY_URL} target="_blank">
            Cookie Policy
          </LegalLink>
        </div>
      </div>
    </Container>
  </div>
);
export default Footer;
const LegalLink = styled.a`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
`;
const Hrline = styled.hr`
  border: 0;
  border-bottom: 1px solid #dddddd;
  display: block;
  width: 90%;
`;
