import React, { useEffect, useState } from 'react';

type CountDownProp = {
  seconds: number;
  onFinish: () => void;
};

const CountDown = ({ seconds, onFinish }: CountDownProp) => {
  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  useEffect(() => {
    setTimeLeft(seconds);
  }, [seconds]);

  useEffect(() => {
    // exit early when we reach 0
    if (timeLeft === null) {
      return;
    }

    if (timeLeft === 0) {
      onFinish();
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return <span>{timeLeft} seconds</span>;
};

export default CountDown;
