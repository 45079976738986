import React from 'react';

const NonCashAcct = ({ size }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size }}
    >
      <path
        d="M4 0C2.89543 0 2 0.89543 2 2V22C2 23.1046 2.89543 24 4 24H16C17.1046 24 18 23.1046 18 22V19.5732C17.2181 19.8496 16.3766 20 15.5 20C11.3579 20 8 16.6421 8 12.5C8 8.35786 11.3579 5 15.5 5C16.3766 5 17.2181 5.15039 18 5.42676V2C18 0.895431 17.1046 0 16 0H4Z"
        fill="#999999"
      />
      <rect x="8" y="2" width="4" height="1" rx="0.5" fill="white" />
      <path
        d="M15.5 19C19.084 19 22 16.084 22 12.5C22 8.91597 19.084 6 15.5 6C11.916 6 9 8.91597 9 12.5C9 16.084 11.916 19 15.5 19Z"
        fill="#666666"
      />
      <path
        d="M18.5246 13.9678C18.5246 12.7435 17.3177 11.7474 15.8344 11.7474C15.1937 11.7474 14.7462 11.4926 14.7462 11.1277C14.7462 10.7718 15.0677 10.4822 15.4628 10.4822C15.8611 10.4822 16.38 10.521 17.2084 10.8083C17.6555 10.9634 18.1589 10.7658 18.3328 10.3671C18.5066 9.96839 18.2851 9.5195 17.8381 9.36447C17.4069 9.2149 17.0309 9.11684 16.7005 9.05228V8.45547C16.7005 8.02768 16.3117 7.68091 15.8319 7.68091C15.3522 7.68091 14.9634 8.02768 14.9634 8.45547V8.97893C13.849 9.18585 13.009 10.0706 13.009 11.1277C13.009 11.7492 13.314 12.3141 13.8679 12.7183C14.3861 13.0966 15.0661 13.2965 15.8343 13.2965C16.3509 13.2965 16.7873 13.6039 16.7873 13.9678C16.7873 14.3117 16.4288 14.6133 16.0201 14.6133C15.7012 14.6133 14.901 14.509 14.1115 14.2291C13.6658 14.0712 13.1607 14.2653 12.9835 14.6628C12.8063 15.0604 13.0241 15.5107 13.4699 15.6687C13.9729 15.847 14.4976 15.9742 14.9633 16.0548V16.511C14.9633 16.9388 15.3521 17.2855 15.8319 17.2855C16.3116 17.2855 16.7004 16.9388 16.7004 16.511V16.0784C17.0976 15.9788 17.4646 15.7925 17.7705 15.5297C18.2568 15.1118 18.5246 14.5572 18.5246 13.9678Z"
        fill="white"
      />
    </svg>
  );
};

export default NonCashAcct;
