import { DataUsers } from 'containers/Administration/Users/List/model';
import AppAPIInstance from 'utils/requestVinova';
import { API } from '../constans';

export interface IPayload {
  page: number;
  limit: number;
  search?: string;
  role_id?: string;
  region?: [];
  area?: [];
  territory?: [];
  active?: string;
}

export const getUsers = async (): Promise<any> => {
  return AppAPIInstance.get(API.ADMINISTRATION.USER.CREATE_USER);
};

export const getListUsers = async (payload?: IPayload): Promise<any> => {
  return AppAPIInstance.post(API.ADMINISTRATION.USER.LIST, payload);
};

export const getUserRoles = async (): Promise<any> => {
  return AppAPIInstance.get(API.ADMINISTRATION.USER.ROLE);
};

export const getUsersDownload = async (): Promise<any> => {
  return AppAPIInstance.get(API.ADMINISTRATION.USER.DOWNLOAD);
};

export const usersBulkUpload = async (payload: any): Promise<any> => {
  return AppAPIInstance.post(API.ADMINISTRATION.USER.UPLOAD, { file_uploaded_url: payload });
};

export const getUsersBulkUploadList = async (payload: any): Promise<any> => {
  return AppAPIInstance.get(API.ADMINISTRATION.USER.UPLOAD, { params: payload });
};

export const getUsersBulkUploadDetail = async (id: any): Promise<any> => {
  return AppAPIInstance.get(`${API.ADMINISTRATION.USER.UPLOAD}/${id}`);
};

export const createUser = async ({ params }: { params: any }) => {
  return AppAPIInstance.post(API.ADMINISTRATION.USER.CREATE_USER, params);
};

export const updateUser = async ({ params, id }: { params: DataUsers; id: any }) => {
  return AppAPIInstance.put(API.ADMINISTRATION.USER.UPDATE(id), params);
};

export const getDetailUser = async (id: any): Promise<any> => {
  return AppAPIInstance.get(API.ADMINISTRATION.USER.GET_DETAIL(id));
};

export const sendMappingHeaders = async (id: number, payload: any) => {
  return AppAPIInstance.post(API.ADMINISTRATION.USER.UPDATE_MAPPING(id), payload);
};

export const getLocations = async (): Promise<any> => {
  return AppAPIInstance.get(`${API.ADMINISTRATION.USER.LOCATIONS}`);
};
