/* eslint-disable no-unused-vars */

import { call, put, takeLatest } from 'redux-saga/effects';
import { showToastError, toastSuccess } from 'constant/types';
import AgencyUserAPI from 'utils/service/AgencyUserAPI';
import { updatePanigation } from 'redux/application/action';

import { GET_LIST_AGENCY_USER, getListAgencyUseruccess } from './action';
import { CrudActionAgencyUser } from './types';

function* getListAgencyUserSaga(action: CrudActionAgencyUser) {
  const { stateTable, onSuccess } = action.payload;
  try {
    const res = yield call(AgencyUserAPI.getListAgencyUser, stateTable);
    if (res.data.response) {
      yield put(updatePanigation(res.data.meta));
      onSuccess(res.data.response);
    }
    yield put(getListAgencyUseruccess(res.data.response));
  } catch (error) {
    showToastError('get list roles fail');
  }
}

function* agencyUserSaga() {
  yield takeLatest(GET_LIST_AGENCY_USER, getListAgencyUserSaga);
}

export default agencyUserSaga;
