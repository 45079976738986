/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Label, Modal, ModalBody, Button } from 'reactstrap';
import { AppContext, convertTvsFormatDate } from 'helpers';
import { NEPAL_TZ } from 'constant';
import moment from 'moment';
import NepaliDate from 'nepali-date';
import { toastr } from 'react-redux-toastr';
import DatepickerElement from './DatepickerElement';

interface Props {
  handleChange: (type: string, f: string, t: string) => void;
  initActive?: string;
}

const DateRangeDropdown = ({ handleChange, initActive = 'Today' }: Props) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const { timezone, fromToDate } = useContext(AppContext);

  const [showDateRangeModal, setShowDateRangeModal] = useState<boolean>(false);
  const today = new Date();
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [active, setActive] = useState<any>({
    // default dropdown value is set to today
    type: 'today',
    fromDate: today,
    toDate: today,
  });

  useEffect(() => {
    setActive({
      type: fromToDate.type,
      fromDate: new Date(fromToDate.f),
      toDate: new Date(fromToDate.t),
    });
  }, [fromToDate]);

  const closeDateRangeModal = () => {
    setShowDateRangeModal(false);
  };

  const getDisplayValue = (type: string, fromDate: Date | string, toDate?: Date | string) => {
    const format = type === 'range' ? 'DD/MM/YYYY' : 'MMM DD, YYYY';
    const fromFormattedDate = convertTvsFormatDate(moment(fromDate).format('DD-MM-YYYY'), timezone, format);
    const toFormattedDate = convertTvsFormatDate(moment(toDate).format('DD-MM-YYYY'), timezone, format);

    if (type === 'today') {
      return `${t('Today')} (${fromFormattedDate})`;
    }
    if (type === 'yesterday') {
      return `${t('Yesterday')} (${fromFormattedDate})`;
    }

    return `${fromFormattedDate} - ${toFormattedDate}`;
  };

  const handleSelectItem = (type: any) => {
    if (type === 'today') {
      setActive({
        type: 'today',
        fromDate: today,
        toDate: today,
      });
      handleChange('today', moment(today).format('yyyy-MM-DD'), moment(today).format('yyyy-MM-DD'));
    } else if (type === 'yesterday') {
      const yesterday = new Date(Date.now() - 864e5);
      setActive({
        type: 'yesterday',
        fromDate: yesterday,
        toDate: yesterday,
      });
      handleChange('yesterday', moment(yesterday).format('yyyy-MM-DD'), moment(yesterday).format('yyyy-MM-DD'));
    } else if (type === 'last-3-days') {
      const fromDate = new Date(Date.now() - 2 * 864e5);
      setActive({
        type: 'last-3-days',
        fromDate,
        toDate: today,
      });
      handleChange('last-3-days', moment(fromDate).format('yyyy-MM-DD'), moment(today).format('yyyy-MM-DD'));
    } else if (type === 'last-7-days') {
      const fromDate = new Date(Date.now() - 6 * 864e5);
      setActive({
        type: 'last-7-days',
        fromDate,
        toDate: today,
      });
      handleChange('last-7-days', moment(fromDate).format('yyyy-MM-DD'), moment(today).format('yyyy-MM-DD'));
    } else if (type === 'range') {
      if (!moment(fromDate).isSameOrBefore(toDate)) {
        // Date range validation
        const toastrOptions = {
          timeOut: 5000, // timeout in milliseconds
          onCloseButtonClick: () => {
            toastr.clean();
          },
          showCloseButton: true,
          closeOnToastrClick: false,
        };
        setFromDate(active.fromDate);
        setToDate(active.toDate);
        toastr.error('', 'Selected date range is invalid.', toastrOptions);
        return;
      }
      setActive({
        type: 'range',
        fromDate,
        toDate,
      });
      handleChange('range', moment(fromDate).format('yyyy-MM-DD'), moment(toDate).format('yyyy-MM-DD'));
      setShowDateRangeModal(false);
    }
  };

  //   useEffect(() => {
  //     setActive({
  //       fromDate,
  //       toDate,
  //     });
  //   }, [fromDate, toDate]);

  const dropDown = () => {
    return (
      <>
        <Modal isOpen={showDateRangeModal} toggle={toggle} centered>
          <ModalBody className="mx-3 my-3">
            <div className="d-flex justify-content-between">
              <div style={{ color: '#263238', fontSize: '20px', fontWeight: 500 }}>{t('Selected date range')}</div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => closeDateRangeModal()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="d-flex justify-content-start mt-4 mb-4 align-items-center">
              <DateLabel className="mr-3"> {t('From')} </DateLabel>
              <DatepickerElement
                id="transaction_from_date"
                selected={fromDate}
                onChange={(date: Date) => setFromDate(date)}
              />
              <DateLabel className="mx-3 text-center"> {t('to')} </DateLabel>
              <DatepickerElement
                id="transaction_to_date"
                selected={toDate}
                minDate={fromDate}
                onChange={(date: Date) => setToDate(date)}
              />
            </div>
            <div className="text-center justify-content-center">
              <Button onClick={() => closeDateRangeModal()} color="cancel" className="px-5 mr-3">
                {t('Cancel')}
              </Button>
              <Button
                onClick={() => {
                  handleSelectItem('range');
                }}
                color="primary"
                className="px-5 ml-3"
              >
                {t('Apply')}
              </Button>
            </div>
          </ModalBody>
        </Modal>
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          style={{
            border: '1px solid #6956BA',
            height: '56px',
            borderRadius: '8px',
            background: '#fff',
          }}
        >
          <DropdownToggleStyle caret color="x" className="p-3 border-0">
            {active ? getDisplayValue(active.type, active.fromDate, active.toDate) : t('Today')}
            {/* <img src={`${process.env.PUBLIC_URL}/images/datepicker.svg`} alt="datepicker" width={18} height={18} /> */}
          </DropdownToggleStyle>
          <DropdownMenu className="mt-2">
            <DropdownItemStyle
              key="today"
              onClick={() => {
                handleSelectItem('today');
              }}
            >
              {t('Today')}
            </DropdownItemStyle>
            <DropdownItemStyle
              key="yesterday"
              onClick={() => {
                handleSelectItem('yesterday');
              }}
            >
              {t('Yesterday')}
            </DropdownItemStyle>
            <DropdownItemStyle
              key="last-3-days"
              onClick={() => {
                handleSelectItem('last-3-days');
              }}
            >
              {t('Last 3 days')}
            </DropdownItemStyle>
            <DropdownItemStyle
              key="last-7-days"
              onClick={() => {
                handleSelectItem('last-7-days');
              }}
            >
              {t('Last 7 days')}
            </DropdownItemStyle>
            <DropdownItemStyle
              key="range"
              onClick={() => {
                setShowDateRangeModal(true);
              }}
            >
              {t('Selected date range')}
            </DropdownItemStyle>
          </DropdownMenu>
        </Dropdown>
      </>
    );
  };

  return dropDown();
};

DateRangeDropdown.defaultProps = { initActive: 'Today' };

export const DropdownItemStyle = styled(DropdownItem)`
  // &.active {
  //   background: #ffcc4d;
  // }
`;

export const DropdownToggleStyle = styled(DropdownToggle)`
  &.btn {
    color: #263238;
    font-size: 16px;
    font-weight: 400;
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }
  }
`;

const DateLabel = styled(Label)`
  color: #263238;
  font-size: 16px;
  font-weight: 400;
`;
export default DateRangeDropdown;
