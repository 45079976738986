/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CardBody, Card, Button } from 'reactstrap';
import { RecordPaymentContext } from 'helpers';
import { formatCurrency } from 'constant';
import { Localization } from 'constant/config';
import styled from 'styled-components';
import { Input } from 'components/form';
import { useFormikContext } from 'formik';
import { calculateTotal } from 'containers/Payment/record-payment';
import AddressGroupField from './pmt-address';

interface Props {
  isAdvancePayment: boolean;
  editableAddress: boolean;
  setEditableAddress: (x: boolean) => void;
  toogleAddress: () => void;
  data: any;
}
const AddressContactRecordPayment = ({
  isAdvancePayment,
  editableAddress,
  setEditableAddress,
  toogleAddress,
  data,
}: Props) => {
  const { loading } = useContext(RecordPaymentContext);
  const { handleReset } = useFormikContext();
  const customer = data?.customer || {};

  const cancelEditAddress = () => {
    toogleAddress();
    handleReset();
  };
  const { t } = useTranslation();

  const advancePaymentDueBreakdown = () => {
    return (
      <div className="pb-4">
        <Group className="d-flex justify-content-between p-2 px-4 odd">
          <Label>{t('No. of Instalments left')}</Label>
          <Value>{data?.instalments_left}</Value>
        </Group>
        <Group className="d-flex justify-content-between p-2 px-4 ">
          <Label>{t('EMI')}</Label>
          <Value>
            {formatCurrency(data?.sales?.emi_due)}{' '}
            <span style={{ fontSize: '14px' }}>{Localization.currencyAbbrev}</span>
          </Value>
        </Group>
        <Group className="d-flex justify-content-between p-2 px-4 odd">
          <Label>{t('Current Due')}</Label>
          <Value>
            {formatCurrency(data?.current_due)} <span style={{ fontSize: '14px' }}> {Localization.currencyAbbrev}</span>
          </Value>
        </Group>
        <Group className="d-flex justify-content-between p-3 px-4 total">
          <Label>{t('Total Outstanding Due')}</Label>
          <Value style={{ color: '#2A2A3E', fontWeight: 500 }}>
            {formatCurrency(data?.total_outstanding_due)}{' '}
            <span style={{ fontSize: '14px' }}> {Localization.currencyAbbrev}</span>
          </Value>
        </Group>
      </div>
    );
  };

  const normalPaymentDueBreakdown = () => {
    return (
      <div className="pb-4">
        <div className="d-flex justify-content-between align-items-center px-4 mb-3">
          <DetailHeader>
            {t('Installment')} #{data.due?.install_num}
          </DetailHeader>
          <Value style={{ color: '#2A2A3E', fontWeight: 500 }}>{Localization.currencyAbbrev}</Value>
        </div>
        <Group className="d-flex justify-content-between p-2 px-4 odd">
          <Label>{t('Principal Due')}</Label>
          <Value>{formatCurrency(data?.due?.principal_due)}</Value>
        </Group>
        {Localization.hiddenPenaltyField ? (
          <Group className="d-flex justify-content-between p-2 px-4">
            <Label>{t('Principal Payment Received')}</Label>
            <Value>{formatCurrency(data?.principal_received)}</Value>
          </Group>
        ) : (
          <>
            <Group className="d-flex justify-content-between p-2 px-4 ">
              <Label>{t('Penalty Due')}</Label>
              <Value>{formatCurrency(data?.due?.penalty_due)}</Value>
            </Group>
            <Group className="d-flex justify-content-between p-2 px-4 odd">
              <Label>{t('Principal Payment Received')}</Label>
              <Value>{formatCurrency(data?.principal_received)}</Value>
            </Group>
            <Group className="d-flex justify-content-between p-2 px-4 ">
              <Label>{t('Penalty Payment Received')}</Label>
              <Value>{formatCurrency(data?.penalty_received)}</Value>
            </Group>
          </>
        )}
        {Localization.activatePPD && (
          <Group className="d-flex justify-content-between p-2 px-4 odd">
            <Label>{t('PPD Received')}</Label>
            <Value>{data?.ppd_received ? formatCurrency(data?.ppd_received) : '0.00'}</Value>
          </Group>
        )}
        <Group className="d-flex justify-content-between p-3 px-4 total">
          <Label>{t('Total Due')}</Label>
          <Value style={{ color: '#2A2A3E', fontWeight: 500 }}>
            {formatCurrency(
              calculateTotal(data?.due, +data?.principal_received, data?.penalty_received, +data?.ppd_received),
            )}
          </Value>
        </Group>
      </div>
    );
  };

  return data?.customer ? (
    <Card>
      <CardBody className="p-0">
        <div className="pt-4">
          <div className="px-4 mb-3">
            <div style={{ color: '#2A2A3E' }}>{t('Customer ID')}</div>
            <div>{data?.acct_number}</div>
          </div>
          <div className="px-4 mb-3">
            <div style={{ color: '#2A2A3E' }}>{t('Customer Name')}</div>
            <div>{data?.customer?.full_name}</div>
          </div>
          <div className="mb-3">
            {!editableAddress ? (
              <div className="px-4">
                <div style={{ color: '#2A2A3E' }}>{t('Customer Address')}</div>
                {customer && (
                  <div>{`${customer?.zipcode}, ${customer?.province}, ${customer?.city}, ${customer?.address}`}</div>
                )}
                <Button color="link" className="border-0 pl-0" onClick={() => setEditableAddress(true)}>
                  <u>{t('Edit')}</u>
                </Button>
              </div>
            ) : (
              <div className="px-2 py-4" style={{ backgroundColor: 'rgba(42, 42, 62, 0.05)' }}>
                <AddressGroupField col={12} />
                <div className="px-3">
                  <Input type="textarea" id="address" label={t('Address')} />
                </div>

                <div className="d-flex justify-content-end mt-5 px-3">
                  <Button
                    color="cancel"
                    className="text-danger border-0"
                    onClick={cancelEditAddress}
                    style={{ backgroundColor: 'transparent' }}
                  >
                    {t('Cancel')}
                  </Button>
                  <Button type="submit" color="primary" disabled={loading}>
                    {t('Save Address')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr />
        {data?.is_advance_payment ? advancePaymentDueBreakdown() : normalPaymentDueBreakdown()}
      </CardBody>
    </Card>
  ) : (
    <div>Loading ...</div>
  );
};

const Group = styled.div`
  background-color: #fff;
  &.odd {
    background-color: rgba(42, 42, 62, 0.05);
  }
  &.total {
    background-color: #ffcc4d;
  }
`;

const Label = styled.div`
  color: rgba(42, 42, 62, 0.75);
  opacity: 0.7;
`;

const Value = styled.div`
  color: #5a6168;
`;

const DetailHeader = styled.div`
  color: #5a6168;
  font-size: 20px;
  font-family: Roboto Condensed, sans-serif;
`;
export default AddressContactRecordPayment;
