import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Nav } from 'reactstrap';

interface Props {
  page: number;
  pages: number;
  handlePrevClick: () => {};
  handleNextClick: () => {};
}

const PdfNavBar = (props) => {
  const { page, pages, handlePrevClick, handleNextClick } = props;
  return (
    <div>
      <NavButton onClick={handlePrevClick}>Prev</NavButton>
      <span style={{ marginLeft: 10, marginRight: 10 }}>{`${page} of ${pages}`}</span>
      <NavButton onClick={handleNextClick}>Next</NavButton>
    </div>
  );
};

PdfNavBar.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  handlePrevClick: PropTypes.func.isRequired,
  handleNextClick: PropTypes.func.isRequired,
};

export default PdfNavBar;

const NavButton = styled(Button)`
  border: 1px solid #6956ba;
  border-radius: 8px !important;
  background: #6956ba;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #ffffff;
  padding: 10px 8px !important;
  height: 36px;
  &:hover {
    background: #b1b6ff;
    border: 1px solid #b1b6ff;
  }
`;
