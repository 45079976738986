/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/naming-convention */
import { FULL_PAYMENT, PARTIAL_PAYMENT, PROMISE_TO_PAY, formatCurrency } from 'constant';
import { Localization } from 'constant/config';
import React from 'react';
import i18n from 'i18n';
import { t } from 'i18next';

export const PHONE_VALIDATE = [
  {
    value: '+65',
    validate: {
      length: 8,
    },
  },
  {
    value: '+63',
    validate: {
      length: 10,
    },
  },
  {
    value: '+977',
    validate: {
      length: 10,
    },
  },
  {
    value: '+91',
    validate: {
      length: 10,
    },
  },
];

const RegexEnglish = /^[-A-Za-z0-9._ ]*$/;

const checkAmountCurrency = (cash: string | number) => {
  const splitDec = cash?.toString().replace(/,/gi, '');
  const regexp = /^\d+(\.\d{1,2})?$/;
  if (!regexp.test(splitDec)) {
    return t('Please check amount received');
  }
  return false;
};

const cashReceivedValidation = (values: any, note: string, currency: string) => {
  const cash = values?.cash_received?.toString()?.replace(/,/gi, '') || null;
  if (note === PARTIAL_PAYMENT || note === FULL_PAYMENT) {
    if (!values?.cash_received) return t('Please enter Total Payment Received');
    if (checkAmountCurrency(values?.cash_received)) return t('Please check amount received');
    if (+cash === 0) {
      const ppdAmt = values?.ppd_amount?.toString().replace(/,/gi, '') || 0;
      if (+values.total_expected > +ppdAmt) return t('Total Payment Received can not be zero');
    }
    if (!cash) return t('Please enter Total Payment Received');
  }

  if (note === FULL_PAYMENT && +cash < +(+values.total_expected - +values.ppd_amount).toFixed(2)) {
    return t('Total Payment Received must be equal to or greater than Total Due');
  }
  const { penalty_actual, penalty_min } = values;
  const penaltyMin = Math.min(+penalty_actual, +penalty_min);
  if (note === PARTIAL_PAYMENT) {
    if (+cash >= +values.total_expected) {
      return t('Total Payment Received must be lesser than Total Due');
    }
    if (+cash < +penaltyMin) {
      return `${t('Total Payment Received must be equal to or greater than')} ${formatCurrency(
        penaltyMin,
      )} ${currency}`;
    }
  }
  return false;
};

const penaltyValidation = (values: any, note: string, currency: string) => {
  const penalty = +values?.penalty_payment?.toString()?.replace(/,/gi, '') || 0;
  const { penalty_actual, penalty_min } = values;
  const penaltyMin = Math.min(+penalty_actual, +penalty_min);

  if (note === PARTIAL_PAYMENT || note === FULL_PAYMENT) {
    if (checkAmountCurrency(values?.penalty_payment)) return t('Please check amount');
    if (+penalty < +penaltyMin) {
      return `${t('Penalty Payment must be equal to or greater than Penalty Min')} (${formatCurrency(
        penaltyMin,
      )} ${currency})`;
    }

    // if (+penalty > +penalty_actual) {
    //   return `${t('Penalty Payment must be equal to or lesser than Penalty Due')} (${formatCurrency(
    //     penalty_actual,
    //   )} ${currency})`;
    // }
  }

  return false;
};

const principalValidation = (values: any, note: string, currency: string) => {
  if (note === PARTIAL_PAYMENT) {
    if (checkAmountCurrency(values?.principal_payment)) return t('Please check amount');
    const principal = +values?.principal_payment?.toString()?.replace(/,/gi, '') || 0;
    const cash = +values?.cash_received?.toString()?.replace(/,/gi, '') || 0;

    const principal_actual = +(values.principal_due - values.principal_payments_received).toFixed(2);
    if (+principal > +cash || +principal > +principal_actual) {
      return `${t('Principal Payment must at most be')} ${formatCurrency(principal_actual)} ${currency} ${t(
        'or less',
      )}`;
    }
  }
  return false;
};

const phoneValidation = (values: any) => {
  if (values.cust_phone) {
    if (isNaN(values.cust_phone)) return t('Please enter valid phone number');

    const mappingValidation = PHONE_VALIDATE.filter((item) => item.value === values.country_code)[0];
    if (mappingValidation && values.cust_phone.length !== mappingValidation?.validate?.length) {
      return t('Please enter valid phone number');
    }
  }
  return false;
};

export const validate = (values: any, data?: any) => {
  const errors: any = {};
  const { currency } = data;
  const note = values.payment_note;

  errors.cash_received = cashReceivedValidation(values, note, currency);
  errors.principal_payment = principalValidation(values, note, currency);
  errors.penalty_payment = penaltyValidation(values, note, currency);
  errors.cust_phone = phoneValidation(values);

  if (!values.unable_pay_more && (note === PARTIAL_PAYMENT || note === PROMISE_TO_PAY)) {
    if (!values.ptp_amount) {
      errors.ptp_amount = t('Please enter promise to pay amount');
    }
    if (+values.ptp_amount === 0) {
      errors.ptp_amount = t('Promise to pay amount can not be zero');
    }
    if (values.ptp_amount && checkAmountCurrency(values.ptp_amount)) {
      errors.ptp_amount = t('Please check amount received');
    }
    if (!values.ptp_date) {
      errors.ptp_date = t('Please enter promise to pay date');
    }
    if (!values.ptp_location) {
      errors.ptp_location = t('Please enter promise to pay location');
    }
    if (values.ptp_location === 'Field' && !values.zipcode) {
      errors.zipcode = t('Please enter zipcode');
    } else if (values.ptp_location === 'Field' && values?.zipcode?.length !== Localization.zipcodeLength) {
      errors.zipcode = t(Localization.zipcodeValidationMsg);
    }
    if (values.ptp_location === 'Field' && !values.cust_address2_type) {
      errors.cust_address2_type = t('Please enter address type');
    }
    if (values.ptp_location === 'Field' && !values.city) {
      errors.city = t('Please enter city.');
    }
    if (values.ptp_location === 'Field' && !values.province) {
      errors.province = t('Please enter province.');
    }
    if (values.ptp_location === 'Field' && !values.cust_address2) {
      errors.cust_address2 = t('Please enter address');
    }

    // if (values.ptp_location === 'Field' && !values.ptp_collection_address) {
    //   errors.ptp_collection_address = 'Please enter collection address.';
    // }
  }

  if (values.unable_pay_more && !values.remarks) {
    errors.remarks = t('Please indicate in the Remarks & Feedback field the reason for Unable to Pay.');
  }

  Object.keys(errors).forEach((item: any) => {
    if (!errors[item]) delete errors[item];
  });

  return errors;
};

export const custValidate = (values: any, cities: any) => {
  const errors: any = {};

  if (!values.zipcode) {
    errors.zipcode = t('Please select zipcode');
  }
  if (!values.city) {
    errors.city = t('Please select city');
  }
  if (!values.province) {
    errors.province = t('Please select province');
  }
  // if (!values.address) {
  //   errors.address = 'Please enter address';
  // }

  if (cities) {
    const matchCity = cities.findIndex((item: any) => item.key === values.city);
    if (matchCity === -1) {
      errors.city = t('Please select one');
    }
  }

  return errors;
};

// Credit Debit memo changes
export const memoValidate = (values: any) => {
  const errors: any = {};

  if (!values.ref_no) {
    errors.ref_no = t('Please enter a unique reference number');
  }
  if (!values.memo_reason_code) {
    errors.memo_reason_code = t('Please select a reason code');
  }
  if (!values.memo_type) {
    errors.memo_type = t('Please select a memo type');
  }
  if (!values.memo_amount) {
    errors.memo_amount = t('Please enter amount');
  }
  const memoAmount =
    values.memo_amount || values.memo_amount === 0 ? +values.memo_amount.toString().split(',').join('') : 0;
  if (Number(memoAmount) <= 0) {
    errors.memo_amount = t('Amount should be greater than 0');
  }

  if (values.memo_amount && checkAmountCurrency(values.memo_amount)) {
    errors.memo_amount = t('Please check the amount');
  }
  if (!values.remarks) {
    errors.remarks = t('Please enter remarks');
  }
  return errors;
};

export const broadcastMessageValidate = (values: any) => {
  const errors: any = {};

  if (!values.platform) {
    errors.platform = 'Please choose a platform';
  }

  if (!values.message) {
    errors.message = 'Please add a message';
  }

  if (!values.priority) {
    errors.priority = 'Please choose a priority';
  }

  if (!values.startDate) {
    errors.startDate = 'Please choose a start date';
  }

  if (values.endDate < values.startDate) {
    errors.endDate = 'End date must be equal to or later than start date';
  }

  if (!values.endDate) {
    errors.endDate = 'Please choose an end date';
  }

  if (values.sensitive && values.platform != 'CS' && values.roles.length <= 0) {
    errors.roles = 'Please select at least one role';
  }

  return errors;
};

export const isEnglish = (value: string) => {
  const messageError = {
    message: '',
    status: true,
  };
  if (value === '') {
    messageError.status = true;
    messageError.message = '';
    return messageError;
  }
  if (!RegexEnglish.test(value)) {
    messageError.message = 'Search can only contains alphabets, numbers';
    messageError.status = false;
  }
  return messageError;
};
