/* eslint-disable import/no-anonymous-default-export */

import AppAPIInstance from 'utils/requestVinova';
import { API } from '../constans';
import { ParamsAssets, StateAsset, StateAssetLog } from './type';
import { StateBase } from '../type';

export default {
  getListAssets: async (state?: StateAsset): Promise<any> => {
    return AppAPIInstance.get(API.ADMINISTRATION.ASSETS.GET(state));
  },
  getAllAssets: async (state: StateAsset) => {
    return AppAPIInstance.get(API.ADMINISTRATION.ASSETS.GET_ALL(state));
  },

  getAllAssetsLog: async (state: StateAssetLog) => {
    return AppAPIInstance.get(API.ADMINISTRATION.ASSETS_LOG.GET_ALL(state));
  },

  getBranchMaster: async (state: StateBase) => {
    return AppAPIInstance.get(API.ADMINISTRATION.BRANCH_CODE_MASTER.GET_LIST(state));
  },

  getDetailAssets: async (id: string): Promise<any> => {
    return AppAPIInstance.get(API.ADMINISTRATION.ASSETS.GET_DETAIL(id));
  },
  createAssets: async (payload: ParamsAssets): Promise<any> => {
    return AppAPIInstance.post(API.ADMINISTRATION.ASSETS.CREATE, payload);
  },
  updateAssets: (id: string, payload: ParamsAssets) => {
    return AppAPIInstance.put(API.ADMINISTRATION.ASSETS.UPDATE(id), payload);
  },
  uploadAssets: async (payload, type): Promise<any> => {
    return AppAPIInstance.post(API.ADMINISTRATION.ASSETS.UPLOAD, { file_uploaded_url: payload, type });
  },
  sendMappingHeaders: async (id, payload): Promise<any> => {
    return AppAPIInstance.post(API.ADMINISTRATION.ASSETS.UPDATE_MAPPING(id), payload);
  },
  getListAssetsLog: async (state?: StateAssetLog): Promise<any> => {
    return AppAPIInstance.get(API.ADMINISTRATION.ASSETS_LOG.GET(state));
  },
  getEmpById: async (id: string): Promise<any> => {
    return AppAPIInstance.get(API.ADMINISTRATION.ASSETS.GET_USER_ASSETS(id));
  },
};
