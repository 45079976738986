const bs = [];
bs[2000] = [2000, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];

bs[2001] = [2001, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2002] = [2002, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];

bs[2003] = [2003, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

bs[2004] = [2004, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];

bs[2005] = [2005, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2006] = [2006, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];

bs[2007] = [2007, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

bs[2008] = [2008, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31];

bs[2009] = [2009, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2010] = [2010, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];

bs[2011] = [2011, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

bs[2012] = [2012, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30];

bs[2013] = [2013, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2014] = [2014, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];

bs[2015] = [2015, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

bs[2016] = [2016, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30];

bs[2017] = [2017, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2018] = [2018, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30];

bs[2019] = [2019, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];

bs[2020] = [2020, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2021] = [2021, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2022] = [2022, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30];

bs[2023] = [2023, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];

bs[2024] = [2024, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2025] = [2025, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2026] = [2026, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

bs[2027] = [2027, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];

bs[2028] = [2028, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2029] = [2029, 31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30];

bs[2030] = [2030, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

bs[2031] = [2031, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];

bs[2032] = [2032, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2033] = [2033, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];

bs[2034] = [2034, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

bs[2035] = [2035, 30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31];

bs[2036] = [2036, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2037] = [2037, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];

bs[2038] = [2038, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

bs[2039] = [2039, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30];

bs[2040] = [2040, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2041] = [2041, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];

bs[2042] = [2042, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

bs[2043] = [2043, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30];

bs[2044] = [2044, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2045] = [2045, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30];

bs[2046] = [2046, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

bs[2047] = [2047, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2048] = [2048, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2049] = [2049, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30];

bs[2050] = [2050, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];

bs[2051] = [2051, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2052] = [2052, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2053] = [2053, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30];

bs[2054] = [2054, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];

bs[2055] = [2055, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2056] = [2056, 31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30];

bs[2057] = [2057, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

bs[2058] = [2058, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];

bs[2059] = [2059, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2060] = [2060, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];

bs[2061] = [2061, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

bs[2062] = [2062, 30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31];

bs[2063] = [2063, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2064] = [2064, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];

bs[2065] = [2065, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

bs[2066] = [2066, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31];

bs[2067] = [2067, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2068] = [2068, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];

bs[2069] = [2069, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

bs[2070] = [2070, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30];

bs[2071] = [2071, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2072] = [2072, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30];

bs[2073] = [2073, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

bs[2074] = [2074, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2075] = [2075, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2076] = [2076, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30];

bs[2077] = [2077, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];

bs[2078] = [2078, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2079] = [2079, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30];

bs[2080] = [2080, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30];

bs[2081] = [2081, 31, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30];

bs[2082] = [2082, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30];

bs[2083] = [2083, 31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30];

bs[2084] = [2084, 31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30];

bs[2085] = [2085, 31, 32, 31, 32, 30, 31, 30, 30, 29, 30, 30, 30];

bs[2086] = [2086, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30];

bs[2087] = [2087, 31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30];

bs[2088] = [2088, 30, 31, 32, 32, 30, 31, 30, 30, 29, 30, 30, 30];

bs[2089] = [2089, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30];

bs[2090] = [2090, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30];

bs[2091] = [2091, 31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30];

bs[2092] = [2092, 30, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30];

bs[2093] = [2093, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30];

bs[2094] = [2094, 31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30];

bs[2095] = [2095, 31, 31, 32, 31, 31, 31, 30, 29, 30, 30, 30, 30];

bs[2096] = [2096, 30, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30];

bs[2097] = [2097, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30];

bs[2098] = [2098, 31, 31, 32, 31, 31, 31, 29, 30, 29, 30, 29, 31];

bs[2099] = [2099, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31];

const leapYears = [];
for (let i = 2000; i < 2100; i++) {
	let totalD = 0;
	for (let j = 1; j <= 12; j++) {
		totalD += bs[i][j];
	}
	if (totalD === 366) leapYears.push(i);
}

const currentYear = 2000;
const currentMonth = 1;
const firstDay = 3;

const daysInEnglish = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const fullDaysInEnglish = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];
const daysInNepali = ['आइत', 'सोम', 'मंगल', 'बुध', 'बिहि', 'शुक्र', 'शनि'];
const fullDaysInNepali = ['आइतवार', 'सोमवार', 'मंगलवार', 'बुधवार', 'बिहिवार', 'शुक्रवार', 'शनिवार'];

const monthsInEnglish = [
	'Baishakh',
	'Jestha',
	'Ashadh',
	'Shrawan',
	'Bhadra',
	'Ashoj',
	'Kartik',
	'Mangsir',
	'Poush',
	'Magh',
	'Falgun',
	'Chaitra',
];

const monthsInNepali = [
	'बैशाख',
	'जेठ',
	'असार',
	'श्रावण',
	'भदौ',
	'असोज',
	'कार्तिक',
	'मंसिर',
	'पुष',
	'माघ',
	'फाल्गुन',
	'चैत्र',
];

const nepaliNumber = ['०', '१', '२', '३', '४', '५', '६', '७', '८', '९'];

const getNepaliNumber = (engNum) => {
	return engNum
		.toString()
		.split('')
		.map((n) => nepaliNumber[n])
		.join('');
};

const getEnglishNumber = (nepNum) => {
	return parseInt(
		nepNum
			.toString()
			.split('')
			.map((n) => nepaliNumber.indexOf(n))
			.join('')
	);
};

const getFormattedDay = (language, length, index) => {
	if (language === 'NE') {
		if (length === 'DDD') {
			return daysInNepali[index];
		} else {
			// length DDDD
			return fullDaysInNepali[index];
		}
	} else {
		// language english
		if (length === 'DDD') {
			return daysInEnglish[index];
		} else {
			// length DDDD
			return fullDaysInEnglish[index];
		}
	}
};
const getFormattedMonth = (language, index) => {
	if (language === 'NE') {
		index = getEnglishNumber(index);
		index--;
		return monthsInNepali[index];
	} else {
		index--;
		// language english
		return monthsInEnglish[index];
	}
};

const getFullEnglishDate = (englishDate) => {
	const splittedDate = englishDate.split('-');
	if (splittedDate.length !== 3) {
		console.error('error spliting the date');
	}

	const year = splittedDate[0];
	const month = splittedDate[1];
	const day = splittedDate[2];
	const selectedDate = `${year}-${splittedDate[1] > 9 ? month : '0' + month}-${
		splittedDate[2] > 9 ? day : '0' + day
	}`;
	return selectedDate;
};

const convertFullDateToNepali = (englishDate) => {
	const splittedDate = englishDate.split('-');
	if (splittedDate.length !== 3) {
		console.error('error spliting the date');
		return -1;
	}
	const year = getNepaliNumber(splittedDate[0]);
	const month = getNepaliNumber(splittedDate[1]);
	const day = getNepaliNumber(splittedDate[2]);
	const selectedNepaliDate = `${year}-${splittedDate[1] > 9 ? month : '०' + month}-${
		splittedDate[2] > 9 ? day : '०' + day
	}`;
	return selectedNepaliDate;
};

export {
	bs,
	daysInNepali,
	daysInEnglish,
	monthsInNepali,
	monthsInEnglish,
	getNepaliNumber,
	getEnglishNumber,
	currentYear,
	currentMonth,
	firstDay,
	leapYears,
	getFormattedDay,
	getFormattedMonth,
	getFullEnglishDate,
	convertFullDateToNepali,
};
