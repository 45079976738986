import { MetaData } from 'constant/types';
import { AssetsLogParse, AssetsParse, TAsset, TAssetLog } from 'containers/Administration/Assets/Types';
import { StateAsset, StateAssetLog } from 'utils/service/AssetAPI/type';
import { BranchMaster, StateBase } from 'utils/service/type';

export const GET_LIST_ASSETS = 'GET_LIST_ASSETS';
export const getListAssets = (payload?: StateAsset) => {
  return {
    type: GET_LIST_ASSETS,
    payload,
  };
};

export const GET_ALL_ASSETS = 'GET_ALL_ASSETS';
export const getAllAsset = (payload: StateAsset) => {
  return {
    type: GET_ALL_ASSETS,
    payload,
  };
};

export const GET_ALL_ASSETS_SUCCESS = 'GET_ALL_ASSETS_SUCCESS';
export const getAllAssetSuccess = (assetsAll: AssetsParse[]) => {
  return {
    type: GET_ALL_ASSETS_SUCCESS,
    assetsAll,
  };
};

export const GET_ALL_ASSETS_LOG = 'GET_ALL_ASSETS_LOG';
export const getAllAssetLog = (payload: StateAssetLog) => {
  return {
    type: GET_ALL_ASSETS_LOG,
    payload,
  };
};

export const GET_ALL_ASSETS_LOG_SUCCESS = 'GET_ALL_ASSETS_LOG_SUCCESS';
export const getAllAssetLogSuccess = (assetsLogAll: AssetsLogParse[]) => {
  return {
    type: GET_ALL_ASSETS_LOG_SUCCESS,
    assetsLogAll,
  };
};

export const GET_LIST_ASSETS_SUCCESS = 'GET_LIST_ASSETS_SUCCESS';
export const getListAssetsSucess = (assets: TAsset[]) => {
  return {
    type: GET_LIST_ASSETS_SUCCESS,
    assets,
  };
};

export const GET_LIST_ASSETS_LOG = 'GET_LIST_ASSETS_LOG';
export const getListAssetsLog = (payload: StateAssetLog) => {
  return {
    type: GET_LIST_ASSETS_LOG,
    payload,
  };
};

export const GET_LIST_ASSETS_LOG_SUCCESS = 'GET_LIST_ASSETS_LOG_SUCCESS';
export const getListAssetsLogSuccess = (assetsLog: TAssetLog[]) => {
  return {
    type: 'GET_LIST_ASSETS_LOG_SUCCESS',
    assetsLog,
  };
};

export const GET_LIST_BRANCH_CODE = 'GET_LIST_BRANCH_CODE';
export const getListBranchCode = (payload: StateBase) => {
  return {
    type: 'GET_LIST_BRANCH_CODE',
    payload,
  };
};

export const GET_LIST_BRANCH_CODE_SUCCESS = 'GET_LIST_BRANCH_CODE_SUCCESS';
export const getListBranchCodeSucess = (branchMaster: BranchMaster[]) => {
  return {
    type: 'GET_LIST_BRANCH_CODE_SUCCESS',
    branchMaster,
  };
};
