/* eslint-disable no-unused-vars */
import { Effect, call, put, select, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { getBookHistory } from 'utils/service/BookAPI';
import { parseDataHistory } from 'constant/types';

import { CrudActionHistory, CrudPanigation } from './types';
import { GET_HISTORY, UPDATE_PANIGATION, getHistorySuccess, savePanigation, updatePanigation } from './action';

function* updatePanigationSaga(action: CrudPanigation) {
  yield put(savePanigation(action.payload));
}
function* getListHistory(action: CrudActionHistory) {
  const { stateHistory } = action.payload;
  try {
    const res = yield call(getBookHistory, stateHistory);
    const { data } = res;
    const metadata = {
      total_count: data.count,
      per_page: 0,
    };
    const responseParse = data.rows.map((item) => parseDataHistory(item));
    yield put(updatePanigation(metadata));
    if (responseParse) {
      yield put(getHistorySuccess(responseParse));
    }
  } catch (error) {
    toastr.error('', 'Get List History Yard Fail', { timeOut: 3000 });
  }
}

function* applicationSaga() {
  yield takeLatest(UPDATE_PANIGATION, updatePanigationSaga);
  yield takeLatest(GET_HISTORY, getListHistory);
}

export default applicationSaga;
