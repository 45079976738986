/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { AppContext } from 'helpers';
import { Localization } from 'constant';

interface Props {
  handleChange: (x: string) => void;
  data: string[];
  initActive?: string;
}

const BranchDropdown = ({ data, handleChange, initActive = 'All' }: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const { branch } = useContext(AppContext);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [active, setActive] = useState<string>(initActive);

  useEffect(() => {
    setActive(branch);
  }, [branch]);

  const handleSelectItem = (item: string) => {
    setActive(item);
    handleChange(item);
  };

  const renderItem = (item: string) => {
    return (
      <DropdownItem key={item} onClick={() => handleSelectItem(item)}>
        {item}
      </DropdownItem>
    );
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      style={{
        border: '1px solid #6956BA',
        height: '56px',
        borderRadius: '8px',
        background: '#fff',
      }}
    >
      <DropdownToggleStyle caret color="x" className="p-3 border-0">
        {active === 'All' ? `All ${Localization.branches}` : active}
      </DropdownToggleStyle>
      <DropdownMenu className="mt-2">
        <DropdownItem key="All" onClick={() => handleSelectItem('All')}>
          All {Localization.branches}
        </DropdownItem>
        {data.map((item) => renderItem(item))}
      </DropdownMenu>
    </Dropdown>
  );
};

BranchDropdown.defaultProps = { initActive: 'All' };

export const DropdownToggleStyle = styled(DropdownToggle)`
  &.btn {
    color: #263238;
    font-size: 16px;
    font-weight: 400;
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }
  }
`;

export default BranchDropdown;
