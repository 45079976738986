import { FULL_PAYMENT } from 'constant';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PPDBox = ({ payment_note, isEligible, is_overdue, ppdMsg }: any) => {
  const { t } = useTranslation();
  const eligibilityMsg = () => {
    if (!isEligible) {
      return <div style={{ color: '#5A6168' }}>{ppdMsg}</div>;
    }
    return <div style={{ color: '#00A061' }}>{ppdMsg}</div>;
  };

  return (
    <>
      <div className="d-flex flex-column">
        <div style={{ color: '#2A2A3E' }}>{t('Prompt Payment Discount (PPD)')}</div>
        {eligibilityMsg()}
      </div>
    </>
  );
};

export default PPDBox;
