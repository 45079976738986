/* eslint-disable react/no-unknown-property */
import React from 'react';

const CashAcct = ({ size }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.53125 7.73438C8.53125 3.47339 12.0046 0 16.2656 0C20.5266 0 24 3.47339 24 7.73438C24 11.4469 21.3703 14.5546 17.8829 15.3C17.3906 14.5406 16.5328 14.0625 15.5625 14.0625H13.0593C13.0468 14.0612 13.0347 14.0601 13.0231 14.059C12.9013 14.0475 12.8285 14.0406 12.7078 13.95C12.0046 13.3312 11.175 12.8812 10.2891 12.6281C9.19214 11.2921 8.53125 9.59062 8.53125 7.73438ZM16.4188 6.56452C18.0074 6.56452 19.2998 7.56013 19.2998 8.78386C19.2998 9.37294 19.013 9.92734 18.4923 10.345C18.1647 10.6077 17.7717 10.7939 17.3463 10.8935V11.3258C17.3463 11.7534 16.9299 12.1 16.4161 12.1C15.9024 12.1 15.486 11.7534 15.486 11.3258V10.8699C14.9873 10.7893 14.4254 10.6622 13.8866 10.484C13.4093 10.3261 13.1761 9.8759 13.3658 9.47854C13.5555 9.08123 14.0964 8.88721 14.5738 9.04507C15.4193 9.32483 16.2762 9.42903 16.6177 9.42903C17.0555 9.42903 17.4394 9.12756 17.4394 8.78386C17.4394 8.42018 16.972 8.1129 16.4188 8.1129C15.5961 8.1129 14.8678 7.91305 14.3129 7.53497C13.7198 7.13092 13.3931 6.5663 13.3931 5.94516C13.3931 4.88858 14.2927 4.00422 15.486 3.79739V3.27419C15.486 2.84661 15.9025 2.5 16.4162 2.5C16.93 2.5 17.3464 2.84661 17.3464 3.27419V3.87071C17.7002 3.93524 18.1028 4.03325 18.5646 4.18275C19.0434 4.3377 19.2806 4.78639 19.0944 5.1849C18.9082 5.58338 18.3691 5.78088 17.8903 5.62585C17.0032 5.3387 16.4475 5.29999 16.0209 5.29999C15.5978 5.29999 15.2535 5.58942 15.2535 5.94516C15.2535 6.30984 15.7327 6.56452 16.4188 6.56452Z"
        fill="#666666"
      />
      <path
        d="M23.7891 15.5202C23.7046 15.3796 23.5922 15.2671 23.4656 15.1687C22.9479 14.7157 22.0756 14.7585 21.5672 15.3093L18.375 18.9937L18.2766 19.1061C17.8829 19.542 17.3203 19.7812 16.7297 19.7812H11.2266C10.8328 19.7812 10.5234 19.4717 10.5234 19.078C10.5234 18.6842 10.8328 18.3749 11.2266 18.3749H15.5625C16.3359 18.3749 16.9688 17.7421 16.9688 16.9687V16.9546C16.9547 16.1812 16.3359 15.5624 15.5625 15.5624H12.9703C12.5486 15.5624 12.0967 15.4076 11.7328 15.0983C10.0172 13.5937 7.51406 13.4952 5.67188 14.7749V23.4233C7.06406 23.8031 8.49844 23.9999 9.94683 23.9999H16.2656C17.8125 23.9999 19.275 23.2687 20.2031 22.0312L23.5782 17.5312C24.0706 16.8848 24.1077 15.9984 23.7891 15.5202Z"
        fill="#999999"
      />
      <path
        d="M2.15625 14.1562H0.703125C0.314484 14.1562 0 14.4707 0 14.8594V23.2969C0 23.6855 0.314484 24 0.703125 24H2.15625C3.31941 24 4.26562 23.0538 4.26562 21.8906V16.2656C4.26562 15.1025 3.31941 14.1562 2.15625 14.1562Z"
        fill="#666666"
      />
    </svg>
  );
};

export default CashAcct;
