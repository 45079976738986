/* eslint-disable consistent-return */
import { ListHistory, MetaData } from 'constant/types';
import { GET_HISTORY_SUCCESS, SAVE_PANIGATION } from './action';

export type AssetsType = {
  metadata: MetaData;
  listHistory: ListHistory[];
};

const defaultState: AssetsType = {
  metadata: null,
  listHistory: [],
};

export default function applicationReducer(state = defaultState, action: any) {
  switch (action.type) {
    case SAVE_PANIGATION:
      return {
        ...state,
        metadata: action.metadata,
      };
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        listHistory: action.payload.listHistory,
      };
    default:
      return state;
  }
}
