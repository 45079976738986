import React, { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

export default function ModalPortal({ children, visible, setVisible, closeOnClickOutside = false }) {
  const ref = useRef<any>();
  useEffect(() => {
    if (closeOnClickOutside) {
      const handler = (event) => {
        if (visible && ref.current && ref.current.contains(event.target)) {
          setVisible(false);
        }
      };
      document.addEventListener('mousedown', handler);
      document.addEventListener('touchstart', handler);
      return () => {
        document.removeEventListener('mousedown', handler);
        document.removeEventListener('touchstart', handler);
      };
    }

    return null;
  }, [visible]);
  if (!visible) return null;
  return createPortal(
    <>
      <div
        ref={ref}
        style={{
          position: 'fixed',
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
          backgroundColor: '#000',
          opacity: 0.5,
          zIndex: 9999,
        }}
      />
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
          // padding: '50px',
          zIndex: 9999,
          //   maxWidth: 1000,
          borderRadius: 10,
        }}
      >
        {children}
      </div>
    </>,
    document.body,
  );
}
