/* eslint-disable import/no-cycle */
import { IYard } from 'containers/Administration/Yards/type';
import { CrudAction, StateTableYard } from './types';

export const GET_LIST_YARD = 'GET_LIST_YARD';
export const getListYard = (stateTableYard: StateTableYard): CrudAction => {
  return {
    type: GET_LIST_YARD,
    payload: {
      stateTable: stateTableYard,
    },
  };
};

export const GET_LIST_YARD_SUCCESS = 'GET_LIST_YARD_SUCCESS';
export const getListYardSuccess = (yards: IYard[]): CrudAction => {
  return {
    type: GET_LIST_YARD_SUCCESS,
    payload: {
      yards,
    },
  };
};

export const GET_ALL_YARD = 'GET_ALL_YARD';
export const getAllYard = (stateTableYard: StateTableYard): CrudAction => {
  return {
    type: GET_ALL_YARD,
    payload: {
      stateTable: stateTableYard,
    },
  };
};

export const GET_ALL_YARD_SUCCESS = 'GET_ALL_YARD_SUCCESS';
export const getAllYardSuccess = (yardAll: IYard[]): CrudAction => {
  return {
    type: GET_ALL_YARD_SUCCESS,
    payload: {
      yardAll,
    },
  };
};

export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';
export const downloadDocument = (id: number): CrudAction => {
  return {
    type: DOWNLOAD_DOCUMENT,
    payload: {
      id,
    },
  };
};
