/* eslint-disable import/no-anonymous-default-export */

import { StateTableYard } from 'redux/yard/types';
import AppAPIInstance from 'utils/requestVinova';
import { YardData } from 'containers/Administration/Yards/type';
import { API } from '../constans';

export default {
  getListYard: async (state: StateTableYard): Promise<any> => {
    return AppAPIInstance.get(API.ADMINISTRATION.YARD.GET_LIST(state));
  },
  getAllYard: async (state: StateTableYard): Promise<any> => {
    return AppAPIInstance.get(API.ADMINISTRATION.YARD.GET_ALL(state));
  },
  getLinkDownload: async (id: number): Promise<any> => {
    return AppAPIInstance.get(API.ADMINISTRATION.YARD.GET_LINK(id));
  },
  getGenerateCode: async (): Promise<any> => {
    return AppAPIInstance.get(API.ADMINISTRATION.YARD.GET_CODE);
  },
  getPhoneNumber: async (data): Promise<any> => {
    return AppAPIInstance.get(API.ADMINISTRATION.YARD.GET_PHONE_NUMBER, { params: data });
  },
  getDetailYard: async (id: string): Promise<any> => {
    return AppAPIInstance.get(API.ADMINISTRATION.YARD.GET_DETAIL(id));
  },
  createYard: async (payload: YardData): Promise<any> => {
    return AppAPIInstance.post(API.ADMINISTRATION.YARD.CREATE, payload);
  },
  updateYard: (id: string, payload: YardData) => {
    return AppAPIInstance.put(API.ADMINISTRATION.YARD.UPDATE(id), payload);
  },
  downloadDocument: (id: number): Promise<any> => {
    return AppAPIInstance.get(API.ADMINISTRATION.YARD.DOWNLOAD_DOCUMENT.replace(':id', id.toString()));
  },
};
