import { TStateAgencyUser } from 'utils/service/AgencyUserAPI/type';

/* eslint-disable import/no-cycle */
export const GET_LIST_AGENCY_USER = 'GET_LIST_AGENCY_USER';
export const getListAgencyUser = (stateTable: TStateAgencyUser, onSuccess?: (res?: any) => void) => {
  return {
    type: GET_LIST_AGENCY_USER,
    payload: {
      stateTable,
      onSuccess,
    },
  };
};

export const GET_LIST_AGENCY_USER_SUCCESS = 'GET_LIST_AGENCY_USER_SUCCESS';
export const getListAgencyUseruccess = (data: any) => {
  return {
    type: GET_LIST_AGENCY_USER_SUCCESS,
    payload: {
      data,
    },
  };
};
