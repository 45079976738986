/* eslint-disable no-unused-vars */
import { Effect, call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import yardApi from 'utils/service/yardApi';
import { IYard, parseYard } from 'containers/Administration/Yards/type';
import { updatePanigation } from 'redux/application/action';

import { DOWNLOAD_DOCUMENT, GET_ALL_YARD, GET_LIST_YARD, getAllYardSuccess, getListYardSuccess } from './action';
import { CrudAction } from './types';

function* getListYardSaga(action: CrudAction): Generator<Effect, void, any> {
  const { stateTable } = action.payload;
  try {
    const res = yield call(yardApi.getListYard, stateTable);
    const {
      data: { yards, meta },
    } = res;
    yield put(updatePanigation(meta));
    if (yards) {
      const resParse: IYard[] = yards.rows.map((item) => parseYard(item));
      yield put(getListYardSuccess(resParse));
    }
  } catch (error) {
    toastr.error('', 'get list yard fail', { timeOut: 3000 });
  }
}

function* getAllYardSaga(action: CrudAction): Generator<Effect, void, any> {
  const { stateTable } = action.payload;
  try {
    const res = yield call(yardApi.getAllYard, stateTable);
    const {
      data: { yards },
    } = res;
    if (yards) {
      const resParse: IYard[] = yards.rows.map((item) => parseYard(item));
      yield put(getAllYardSuccess(resParse));
    }
  } catch (error) {
    toastr.error('', 'get list yard fail', { timeOut: 3000 });
  }
}

function* getLinkDownLoad(action: CrudAction): Generator<Effect, void, any> {
  const { id } = action.payload;
  try {
    const res = yield call(yardApi.getLinkDownload, id);
    window.open(res.data, '_self', 'file_document_yard');
  } catch (error) {
    toastr.error('', `${error?.message}`, { timeOut: 3000 });
  }
}
function* yardSaga() {
  yield takeLatest(GET_LIST_YARD, getListYardSaga);
  yield takeLatest(GET_ALL_YARD, getAllYardSaga);
  yield takeLatest(DOWNLOAD_DOCUMENT, getLinkDownLoad);
}

export default yardSaga;
