import * as React from 'react';

const ActivitiLog = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 2C0.895182 2 0 2.89518 0 4C0 5.10482 0.895182 6 2 6C3.10482 6 4 5.10482 4 4C4 2.89518 3.10482 2 2 2Z"
      fill="white"
    />
    <path
      d="M30.0001 2H8.66675C7.56193 2 6.66675 2.89518 6.66675 4C6.66675 5.10482 7.56193 6 8.66675 6H30.0001C31.1049 6 32.0001 5.10482 32.0001 4C32.0001 2.89518 31.1049 2 30.0001 2Z"
      fill="white"
    />
    <path
      d="M2 10C0.895182 10 0 10.8952 0 12C0 13.1048 0.895182 14 2 14C3.10482 14 4 13.1048 4 12C4 10.8952 3.10482 10 2 10Z"
      fill="white"
    />
    <path
      d="M30.0001 10H8.66675C7.56193 10 6.66675 10.8952 6.66675 12C6.66675 13.1048 7.56193 14 8.66675 14H30.0001C31.1049 14 32.0001 13.1048 32.0001 12C32.0001 10.8952 31.1049 10 30.0001 10Z"
      fill="white"
    />
    <path
      d="M2 18C0.895182 18 0 18.8952 0 20C0 21.1048 0.895182 22 2 22C3.10482 22 4 21.1048 4 20C4 18.8952 3.10482 18 2 18Z"
      fill="white"
    />
    <path
      d="M12.0001 18H8.66675C7.56193 18 6.66675 18.8952 6.66675 20C6.66675 21.1048 7.56193 22 8.66675 22H12.0001C13.1049 22 14.0001 21.1048 14.0001 20C14.0001 18.8952 13.1049 18 12.0001 18Z"
      fill="white"
    />
    <path
      d="M2 26C0.895182 26 0 26.8952 0 28C0 29.1048 0.895182 30 2 30C3.10482 30 4 29.1048 4 28C4 26.8952 3.10482 26 2 26Z"
      fill="white"
    />
    <path
      d="M13.3334 26H8.66675C7.56193 26 6.66675 26.8952 6.66675 28C6.66675 29.1048 7.56193 30 8.66675 30H13.3334C14.4382 30 15.3334 29.1048 15.3334 28C15.3334 26.8952 14.4382 26 13.3334 26Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.074 22.87L27.8199 24.4997C28.1051 24.6626 28.1947 25.0293 28.0236 25.3145C27.8607 25.5915 27.5021 25.673 27.2251 25.51L24.251 23.7256C23.9984 23.5789 23.8518 23.31 23.8518 23.0249V19.1789C23.8518 18.853 24.1125 18.5923 24.4384 18.5923H24.4873C24.8133 18.5923 25.074 18.853 25.074 19.1789V22.87ZM17.3333 22.6663C17.3333 18.6167 20.617 15.333 24.6666 15.333C28.7162 15.333 31.9999 18.6167 31.9999 22.6663C31.9999 26.716 28.7162 29.9997 24.6666 29.9997C20.617 29.9997 17.3333 26.716 17.3333 22.6663Z"
      fill="white"
    />
  </svg>
);

export default ActivitiLog;
