import React, { useEffect, useState } from 'react';

const Info = ({ className, size }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size }}
    >
      <path
        d="M12 0C5.40891 0 0 5.40891 0 12C0 18.5911 5.40891 24 12 24C18.5911 24 24 18.5911 24 12C24 5.40891 18.5911 0 12 0ZM13.4062 17.625C13.4062 18.4002 12.7752 19.0312 12 19.0312C11.2248 19.0312 10.5938 18.4002 10.5938 17.625V10.5938C10.5938 9.81853 11.2248 9.1875 12 9.1875C12.7752 9.1875 13.4062 9.81853 13.4062 10.5938V17.625ZM12 7.78125C11.2248 7.78125 10.5938 7.15022 10.5938 6.375C10.5938 5.59978 11.2248 4.96875 12 4.96875C12.7752 4.96875 13.4062 5.59978 13.4062 6.375C13.4062 7.15022 12.7752 7.78125 12 7.78125Z"
        fill="#39C86A"
      />
    </svg>
  );
};

export default Info;
