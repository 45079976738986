/* eslint-disable react/require-default-props */
import React from 'react';

interface IArrow {
  border: number;
  length: number;
  color: string;
  rotate?: number;
}

const Arrow = ({ border, length, color, rotate }: IArrow) => {
  return (
    <div
      style={{
        borderBottom: `${border}px solid ${color}`,
        borderRight: `${border}px solid ${color}`,
        transform: `rotate(${rotate || '-45'}deg)`,
        width: `${length}px`,
        height: `${length}px`,
      }}
    />
  );
};

export default Arrow;
