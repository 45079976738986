import { RecordPaymentContext } from 'helpers';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody } from 'reactstrap';
import { OtpInput } from 'components/form';
import { useFormikContext } from 'formik';
import { TvsCountdown } from 'components/elements';

const OtpPayment = () => {
  const { t } = useTranslation();
  const { showOtp, toggleOtp, sendOtp } = useContext(RecordPaymentContext);
  const { values, isSubmitting, handleSubmit } = useFormikContext<any>();
  const [disabledResend, setDisabledResend] = useState(true);
  const [attempts, setAttempts] = useState(1);

  const handleFinishCountdown = () => {
    setDisabledResend(false);
  };

  const handleResendOtp = () => {
    setDisabledResend(true);
    setAttempts((oldAttempts) => {
      const newAttempts = oldAttempts + 1;
      sendOtp(
        values.country_code,
        values.cust_phone,
        newAttempts,
        values.payment_note,
        values.is_ptp,
        values.cash_received,
        '0',
        values.ptp_amount,
        values.ptp_date,
      );
      return newAttempts;
    });
  };

  return (
    <Modal isOpen={showOtp} toggle={toggleOtp} centered size="md" backdrop="static">
      <ModalBody className="p-4">
        <h1 style={{ fontWeight: 300 }} className="d-flex justify-content-between">
          {t('OTP Verification')}
          <Button color="close" onClick={toggleOtp}>
            <img src={`${`${process.env.PUBLIC_URL}/images/close.png`}`} alt="close" />
          </Button>
        </h1>
        <div>
          {t('Please type the OTP code sent to')} {values.country_code} {values.cust_phone}
        </div>
        <div className="m-5 py-3">
          <OtpInput
            id="payment_otp"
            numInputs={6}
            shouldAutoFocus
            inputStyle={{
              width: '50px',
              height: '57px',
              borderRadius: '4px',
              border: '1px solid #D9D9D9',
            }}
            containerStyle={{
              justifyContent: 'space-between',
            }}
          />
        </div>
        <div className="text-center">
          <div className="mb-3">
            <Button
              color="primary"
              className="py-2 px-5"
              disabled={!disabledResend}
              onClick={() => handleSubmit(values)}
            >
              {t('Submit')}
            </Button>
          </div>
          <div>
            <Button color="x" disabled={disabledResend || isSubmitting} onClick={() => handleResendOtp()}>
              {t('Resend OTP')}(
              {disabledResend && <TvsCountdown seconds={90} onFinish={() => handleFinishCountdown()} />})
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default OtpPayment;
