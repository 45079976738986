/* eslint-disable import/no-cycle */
export const GET_LIST_ROLES = 'GET_LIST_ROLES';
export const getListRoles = (onSuccess?: (res?: any) => void) => {
  return {
    type: GET_LIST_ROLES,
    payload: {
      onSuccess,
    },
  };
};

export const GET_LIST_ROLES_SUCCESS = 'GET_LIST_ROLES_SUCCESS';
export const getListRolesSuccess = (data: any) => {
  return {
    type: GET_LIST_ROLES_SUCCESS,
    payload: {
      data,
    },
  };
};
