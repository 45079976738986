import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useFormikContext } from 'formik';
import { Input, Select } from 'components/form';
import { TvsIconInfo, TvsAlert } from 'components/icons';
import { formatCurrency, FULL_PAYMENT, PARTIAL_PAYMENT } from 'constant';
import { RecordPaymentContext } from 'helpers';
import { Localization } from 'constant/config';
import { boolean } from 'yup';
import styles from 'styled-components';

const CashReceivedStep = ({
  isAdvancePayment,
  onPaymentDetailChange,
  instalmentsLeft,
  expectedCurrentDueAfterPayment,
  expectedTotalOutstandingDueAfterPayment,
}) => {
  const { t } = useTranslation();
  const { currency } = useContext(RecordPaymentContext);
  const { values, setValues } = useFormikContext<any>();
  const note = values?.payment_note;

  const generateOptions = (n) => {
    const l = [];
    for (let i = 1; i <= n; i++) {
      l.push(`${i}`);
    }
    return l;
  };

  const numInstalaments = (
    <Col lg={6}>
      <Select
        label="No. of Instalments to pay"
        id="num_instalments"
        options={generateOptions(instalmentsLeft)}
        disabled={isAdvancePayment}
      />
    </Col>
  );

  const totalPaymentReceived = (
    <Col lg={6}>
      <Input
        label={t('Total Payment Received')}
        id="cash_received"
        inputIcon={<div>{currency}</div>}
        placeholder={t('Type amount')}
        formatType="currency"
        handleBlur={(value: string) => {
          const cash = +value?.replace(/,/gi, '') || 0;

          const myValues = { ...values };

          myValues.cash_received = formatCurrency(cash);

          if (note === FULL_PAYMENT && value) {
            const penaltyPayment = +values.penalty_payment.replace(/,/gi, '');

            myValues.principal_payment = formatCurrency((cash - +penaltyPayment).toFixed(2));
          }

          if (note === PARTIAL_PAYMENT && value) {
            const remainingPen = (values.penalty_due - values.penalty_payments_received).toFixed(2);
            const pen = remainingPen < values.penalty_min ? +remainingPen : +values?.penalty_min;
            const pri = formatCurrency(cash - pen);
            myValues.penalty_payment = formatCurrency(pen);
            myValues.principal_payment = formatCurrency(pri);
          }
          setValues(myValues);
          if (isAdvancePayment) {
            onPaymentDetailChange(myValues, true);
          }
        }}
      />
    </Col>
  );

  const ppdAmount = (
    <Col lg={6}>
      <Input
        label={t('Prompt Payment Discount Amount')}
        id="ppd_amount"
        inputIcon={<div>{currency}</div>}
        formatType="currency"
        disabled={true}
      />
    </Col>
  );

  const principalPayment = (
    <Col lg={6}>
      <Input
        label={t('Principal Payment')}
        id="principal_payment"
        inputIcon={<div>{currency}</div>}
        placeholder={t('Type amount')}
        formatType="currency"
        disabled={note === PARTIAL_PAYMENT && +values.penalty_actual < values.penalty_min}
        handleBlur={(value: any) => {
          const principal = +value?.replace(/,/gi, '') || 0;
          const cash = values?.cash_received ? values?.cash_received?.replace(/,/gi, '') : 0;
          if ((note === PARTIAL_PAYMENT && cash) || (note === FULL_PAYMENT && cash)) {
            const myValues = { ...values };
            myValues.penalty_payment = formatCurrency((cash - principal).toFixed(2));
            myValues.principal_payment = formatCurrency(principal);
            setValues(myValues);
          }
        }}
      />
    </Col>
  );

  const penaltyPayment = (
    <Col lg={6}>
      <Input
        hidden={Localization.hiddenPenaltyField}
        label={t('Penalty Payment')}
        id="penalty_payment"
        placeholder={t('Type amount')}
        inputIcon={<div>{currency}</div>}
        formatType="currency"
        disabled={note === PARTIAL_PAYMENT && +values.penalty_actual < values.penalty_min}
        handleBlur={(value: any) => {
          const cash = +values.cash_received?.replace(/,/gi, '') || 0;
          if ((note === PARTIAL_PAYMENT && cash) || (note === FULL_PAYMENT && cash)) {
            const pen = value?.replace(/,/gi, '');
            const myValues = { ...values };
            myValues.principal_payment = formatCurrency((cash - pen).toFixed(2));
            myValues.penalty_payment = formatCurrency(pen);
            setValues(myValues);
          }
        }}
      />
    </Col>
  );

  const calculateRemaining = () => {
    const expected = +values.total_expected;
    const received = +values?.cash_received?.replace(/,/gi, '') || 0;
    return formatCurrency((expected - received - values.ppd_amount).toFixed(2));
  };

  const normalPaymentDisplayRemaining = () => {
    return (
      <FormGroup style={{ background: '#F5F7FF' }} className="p-4 mb-0">
        <div>{t('Current Due after Payment')}</div>
        <div style={{ fontWeight: 500, color: '#000', fontSize: 20 }}>
          {calculateRemaining()} <span style={{ fontSize: '14px' }}>{currency}</span>
        </div>
      </FormGroup>
    );
  };

  const advancePaymentDisplayRemaining = () => {
    return (
      <FormGroup row style={{ background: '#F5F7FF' }} className="p-4 mb-0">
        <Col lg={6}>
          <div>{t('Current Due after Payment')}</div>
          <div style={{ fontWeight: 500, color: '#000', fontSize: 20 }}>
            {formatCurrency(expectedCurrentDueAfterPayment)} <span style={{ fontSize: '14px' }}>{currency}</span>
          </div>
          <div className="mt-2">{t('Total Outstanding Due after Payment')}</div>
          <div style={{ fontWeight: 500, color: '#000', fontSize: 20 }}>
            {formatCurrency(expectedTotalOutstandingDueAfterPayment)}{' '}
            <span style={{ fontSize: '14px' }}>{currency}</span>
          </div>
        </Col>
        <Col lg={6}>
          <div className="d-flex flex-row align-items-start">
            <TvsIconInfo className="mr-1" size="5em" />
            <div>
              {t(
                'Negative due amount means that customer has made certain payment in advance, he can make less for future repayment cycle.',
              )}
            </div>
          </div>
        </Col>
      </FormGroup>
    );
  };

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  useEffect(() => {
    if (values.penalty_payment > values.penalty_actual) setModalVisible(true);
  }, [values]);

  return (
    <>
      <div style={{ background: '#EFF0F7' }} className="pt-4 px-4 pb-2">
        {/* {isAdvancePayment && <FormGroup row>{numInstalaments}</FormGroup>} */}
        <FormGroup row>
          {totalPaymentReceived}
          {values.payment_note === FULL_PAYMENT && values.is_eligible_ppd && Localization.activatePPD && ppdAmount}
        </FormGroup>
        {!Localization.hiddenPenaltyField && (
          <FormGroup row>
            <Col lg={12} className="mb-2">
              <span style={{ fontWeight: 500 }}>{t('Total Payment Received')}</span>{' '}
              {t('includes the following payments')}:
            </Col>
            {principalPayment}
            {penaltyPayment}
            <Modal isOpen={modalVisible} toggle={() => setModalVisible(!modalVisible)} centered>
              {/* <ModalHeader toggle={() => setModalVisible(!modalVisible)}>Additional penalty received</ModalHeader> */}
              <ModalBody style={{ backgroundColor: '#333333', justifyContent: 'center' }}>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setModalVisible(false)}
                    style={{
                      color: '#ffffff',
                      marginRight: 5,
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="d-flex justify-content-center mb-4">
                  <TvsAlert size={50} />
                </div>
                <ModalAlertTitle> Additional penalty received</ModalAlertTitle>
                <ModalAlertText>
                  Penalty received amount is more than penalty due amount. This results in a negative payment due. Do
                  you want to continue recording this payment?
                </ModalAlertText>
                {/* <ModalFooter> */}
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
                  <BackButton
                    // color="primary"
                    onClick={() => {
                      setModalVisible(!modalVisible);

                      const myValues = { ...values };
                      myValues.penalty_payment = formatCurrency(values.penalty_actual);
                      setValues(myValues);
                    }}
                  >
                    Back
                  </BackButton>
                  <ContinueButton color="secondary" onClick={() => setModalVisible(!modalVisible)}>
                    Continue
                  </ContinueButton>
                </div>
              </ModalBody>
              {/* </ModalFooter> */}
            </Modal>
          </FormGroup>
        )}
      </div>
      {isAdvancePayment ? advancePaymentDisplayRemaining() : normalPaymentDisplayRemaining()}
    </>
  );
};

const ModalAlertTitle = styles.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  text-align: center;
`;

const ModalAlertText = styles.div`
margin-top: 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
`;

const ContinueButton = styles(Button)`
  border: 1px solid #6956ba;
  border-radius: 8px !important;
  background: #6956ba;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #ffffff;
  padding: 10px 8px !important;
  width: 120px;
  height: 48px;
  &:hover {
    background: #b1b6ff;
    border: 1px solid #b1b6ff;
  }
`;

const BackButton = styles(Button)`
  border: 1px solid #ffffff;
  border-radius: 8px !important;
  background: #ffffff;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #6956ba;
  padding: 10px 8px !important;
  width: 120px;
  height: 48px;
  margin-right: 24px;
  &:hover {
    background: #b1b6ff;
    border: 1px solid #b1b6ff;
  }
`;

export default CashReceivedStep;
