/* eslint-disable consistent-return */
import { MetaData } from 'constant/types';
import { TypeBidders } from 'containers/Yard/ManageBidders/types';
import { GET_DETAIL_BIDDER_SUCCESS } from './action';
import { CrudActionBidder } from './types';

export type BidderType = {
  metadata?: MetaData;
  bidder?: TypeBidders;
};

const defaultState: BidderType = {
  bidder: null,
};

export default function bidderReducer(state = defaultState, action: CrudActionBidder) {
  switch (action.type) {
    case GET_DETAIL_BIDDER_SUCCESS:
      return {
        ...state,
        bidder: action.payload.bidder,
      };
    default:
      return state;
  }
}
