import axios from 'axios';

export const requestUploadFile = async (id, fileExt, uploadFile) => {
  const tokenKey = 'tvs.token';
  const token = localStorage.getItem(tokenKey);
  const timestamp = Date.now();

  const resp: any = await axios.get(
    `https://api.artha.tvsc.dev.tvsd.co/v1/upload-url?type=dc&id=${id}&suffix=${timestamp}&ext=${fileExt}`,
    {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    },
  );

  const { filename, uploadUrl } = resp.data;
  await axios.put(uploadUrl, uploadFile);
  return { filename, uploadUrl };
};
