import {
  formatCurrency,
  FULL_PAYMENT,
  NOT_CONTACTABLE,
  PARTIAL_PAYMENT,
  PAYMENT_NOTES,
  PROMISE_TO_PAY,
} from 'constant';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Button } from 'reactstrap';

const VisitStep = ({ ppdAmount, isPtp }: any) => {
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext<any>();

  const handleChangePaymentNote = (item: string) => {
    let cash;

    if (item !== FULL_PAYMENT) {
      cash = '';
    } else if (+values.total_expected - +ppdAmount < 0) {
      cash = 0;
    } else {
      cash = (+values.total_expected - +ppdAmount).toFixed(2);
    }

    const penalty = item === FULL_PAYMENT ? values.penalty_actual : '';

    const principal = item === FULL_PAYMENT ? values.principal_actual : '';

    let ppdAmt = 0;

    if (ppdAmount && values?.is_eligible_ppd && item === FULL_PAYMENT) {
      ppdAmt = +values.total_expected < +ppdAmount ? values.total_expected : ppdAmount;
    }

    setValues({
      ...values,
      payment_note: item,
      ppd_amount: formatCurrency(ppdAmt),
      cash_received: formatCurrency(cash),
      cust_phone: item === NOT_CONTACTABLE ? '' : values.cust_phone,
      country_code: item === NOT_CONTACTABLE ? '' : values.country_code,
      principal_payment: formatCurrency(principal),
      penalty_payment: formatCurrency(penalty),
      is_different_address: false,
      unable_pay_more: item === FULL_PAYMENT ? false : values.unable_pay_more,
      is_ptp: !!(item === PARTIAL_PAYMENT || item === PROMISE_TO_PAY),
    });
  };

  return (
    <FormGroup className="px-4 pt-4 pb-1 mb-0" style={{ background: '#E4E6ED' }}>
      <div className="text-small d-flex flex-column mb-3" style={{ color: '#2A2A3E', lineHeight: '22px' }}>
        {t('Transaction Type')} <span style={{ color: '#808080' }}>{t('Select one option')}</span>
      </div>
      <div className="d-flex mb-4">
        {isPtp ? (
          <div key={PROMISE_TO_PAY} className="mr-3">
            <Button
              className="py-2 px-3"
              style={{
                background: values.payment_note === PROMISE_TO_PAY ? '#FFCC4D' : '#fff',
                color: '#2A2A3E',
                border: values.payment_note === PROMISE_TO_PAY ? '1px solid #5A6168' : 'none',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => handleChangePaymentNote(PROMISE_TO_PAY)}
            >
              {t(PROMISE_TO_PAY)}
            </Button>
          </div>
        ) : (
          PAYMENT_NOTES.map((item: string) => (
            <div key={item} className="mr-3">
              <Button
                className="py-2 px-3"
                style={{
                  background: values.payment_note === item ? '#FFCC4D' : '#fff',
                  color: '#2A2A3E',
                  border: values.payment_note === item ? '1px solid #5A6168' : 'none',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => handleChangePaymentNote(item)}
              >
                {t(item)}
              </Button>
            </div>
          ))
        )}
      </div>
    </FormGroup>
  );
};

export default VisitStep;
