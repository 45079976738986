import React from 'react';

interface Props {
  value: string | number;
  children: React.ReactElement;
}
const WrapperEmptyDiv = ({ value, children }: Props) => {
  if (!value || value === '0.0%') {
    return <div>-</div>;
  }

  return children;
};

export default WrapperEmptyDiv;
