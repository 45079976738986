/* eslint-disable import/no-cycle */
import { TypeBidders } from 'containers/Yard/ManageBidders/types';
import { CrudActionBidder } from './types';

export const CREATE_BIDDER = 'CREATE_BIDDER';
export const createBidder = (payload: TypeBidders) => {
  return {
    type: CREATE_BIDDER,
    payload,
  };
};

export const GET_DETAIL_BIDDER = 'GET_DETAIL_BIDDER';
export const getDetailBidder = (id: string, onSuccess: (res?: any) => void): CrudActionBidder => {
  return {
    type: GET_DETAIL_BIDDER,
    payload: {
      id,
      onSuccess,
    },
  };
};

export const APPROVE_BIDDER = 'APPROVE_BIDDER';
export const approveBidder = (
  id: string,
  status: string,
  onSuccess?: (res?: any) => void,
  message?: string,
): CrudActionBidder => {
  return {
    type: APPROVE_BIDDER,
    payload: {
      id,
      status,
      onSuccess,
      message,
    },
  };
};

export const GET_DETAIL_BIDDER_SUCCESS = 'GET_DETAIL_BIDDER_SUCCESS';
export const getDetailBidderSuccess = (bidder: TypeBidders): CrudActionBidder => {
  return {
    type: GET_DETAIL_BIDDER_SUCCESS,
    payload: {
      bidder,
    },
  };
};

export const UPDATE_BIDDER = 'UPDATE_BIDDER';
export const updateBidder = (id: string, paramsBidder: TypeBidders): CrudActionBidder => {
  return {
    type: UPDATE_BIDDER,
    payload: {
      id,
      paramsBidder,
    },
  };
};

export const GET_NUMBER_BIDDER = 'GET_NUMBER_BIDDER';
export const numberBidder = (onSuccess?: (res?: any) => void): CrudActionBidder => {
  return {
    type: GET_NUMBER_BIDDER,
    payload: {
      onSuccess,
    },
  };
};
