/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';

interface Props {
  handleChange: (x: string) => void;
  data: any;
}

const TransactionDropdown = ({ data, handleChange }: Props) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [active, setActive] = useState<string>('All');

  const handleSelectItem = (item: any) => {
    setActive(item.value);
    handleChange(item.id);
  };

  const renderItem = (item: any) => {
    return (
      <DropdownItem key={item} onClick={() => handleSelectItem(item)}>
        {t(item.value)}
      </DropdownItem>
    );
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      style={{
        border: '1px solid #6956BA',
        height: '56px',
        borderRadius: '8px',
        background: '#fff',
      }}
    >
      <DropdownToggleStyle caret color="x" className="p-3 border-0">
        {active === 'All' ? t('All Transactions') : active}
      </DropdownToggleStyle>
      <DropdownMenu className="mt-2">{data.map((item: any) => renderItem(item))}</DropdownMenu>
    </Dropdown>
  );
};

export const DropdownToggleStyle = styled(DropdownToggle)`
  &.btn {
    color: #263238;
    font-size: 16px;
    font-weight: 400;
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }
  }
`;

export default TransactionDropdown;
