export const MOBILE_COUNTRY_CODE = [
  { label: 'Afghanistan', value: '+93', id: 'AF' },
  { label: 'Albania', value: '+355', id: 'AL' },
  { label: 'Algeria', value: '+213', id: 'DZ' },
  { label: 'AmericanSamoa', value: '+1 684', id: 'AS' },
  { label: 'Andorra', value: '+376', id: 'AD' },
  { label: 'Angola', value: '+244', id: 'AO' },
  { label: 'Anguilla', value: '+1 264', id: 'AI' },
  { label: 'Antigua and Barbuda', value: '+1268', id: 'AG' },
  { label: 'Argentina', value: '+54', id: 'AR' },
  { label: 'Armenia', value: '+374', id: 'AM' },
  { label: 'Aruba', value: '+297', id: 'AW' },
  { label: 'Australia', value: '+61', id: 'AU' },
  { label: 'Austria', value: '+43', id: 'AT' },
  { label: 'Azerbaijan', value: '+994', id: 'AZ' },
  { label: 'Bahamas', value: '+1 242', id: 'BS' },
  { label: 'Bahrain', value: '+973', id: 'BH' },
  { label: 'Bangladesh', value: '+880', id: 'BD' },
  { label: 'Barbados', value: '+1 246', id: 'BB' },
  { label: 'Belarus', value: '+375', id: 'BY' },
  { label: 'Belgium', value: '+32', id: 'BE' },
  { label: 'Belize', value: '+501', id: 'BZ' },
  { label: 'Benin', value: '+229', id: 'BJ' },
  { label: 'Bermuda', value: '+1 441', id: 'BM' },
  { label: 'Bhutan', value: '+975', id: 'BT' },
  { label: 'Bosnia and Herzegovina', value: '+387', id: 'BA' },
  { label: 'Botswana', value: '+267', id: 'BW' },
  { label: 'Brazil', value: '+55', id: 'BR' },
  { label: 'British Indian Ocean Territory', value: '+246', id: 'IO' },
  { label: 'Bulgaria', value: '+359', id: 'BG' },
  { label: 'Burkina Faso', value: '+226', id: 'BF' },
  { label: 'Burundi', value: '+257', id: 'BI' },
  { label: 'Cambodia', value: '+855', id: 'KH' },
  { label: 'Cameroon', value: '+237', id: 'CM' },
  { label: 'Canada', value: '+1', id: 'CA' },
  { label: 'Cape Verde', value: '+238', id: 'CV' },
  { label: 'Cayman Islands', value: '+ 345', id: 'KY' },
  { label: 'Central African Republic', value: '+236', id: 'CF' },
  { label: 'Chad', value: '+235', id: 'TD' },
  { label: 'Chile', value: '+56', id: 'CL' },
  { label: 'China', value: '+86', id: 'CN' },
  { label: 'Christmas Island', value: '+61', id: 'CX' },
  { label: 'Colombia', value: '+57', id: 'CO' },
  { label: 'Comoros', value: '+269', id: 'KM' },
  { label: 'Congo', value: '+242', id: 'CG' },
  { label: 'Cook Islands', value: '+682', id: 'CK' },
  { label: 'Costa Rica', value: '+506', id: 'CR' },
  { label: 'Croatia', value: '+385', id: 'HR' },
  { label: 'Cuba', value: '+53', id: 'CU' },
  { label: 'Cyprus', value: '+537', id: 'CY' },
  { label: 'Czech Republic', value: '+420', id: 'CZ' },
  { label: 'Denmark', value: '+45', id: 'DK' },
  { label: 'Djibouti', value: '+253', id: 'DJ' },
  { label: 'Dominica', value: '+1 767', id: 'DM' },
  { label: 'Dominican Republic', value: '+1 849', id: 'DO' },
  { label: 'Ecuador', value: '+593', id: 'EC' },
  { label: 'Egypt', value: '+20', id: 'EG' },
  { label: 'El Salvador', value: '+503', id: 'SV' },
  { label: 'Equatorial Guinea', value: '+240', id: 'GQ' },
  { label: 'Eritrea', value: '+291', id: 'ER' },
  { label: 'Estonia', value: '+372', id: 'EE' },
  { label: 'Ethiopia', value: '+251', id: 'ET' },
  { label: 'Faroe Islands', value: '+298', id: 'FO' },
  { label: 'Fiji', value: '+679', id: 'FJ' },
  { label: 'Finland', value: '+358', id: 'FI' },
  { label: 'France', value: '+33', id: 'FR' },
  { label: 'French Guiana', value: '+594', id: 'GF' },
  { label: 'French Polynesia', value: '+689', id: 'PF' },
  { label: 'Gabon', value: '+241', id: 'GA' },
  { label: 'Gambia', value: '+220', id: 'GM' },
  { label: 'Georgia', value: '+995', id: 'GE' },
  { label: 'Germany', value: '+49', id: 'DE' },
  { label: 'Ghana', value: '+233', id: 'GH' },
  { label: 'Gibraltar', value: '+350', id: 'GI' },
  { label: 'Greece', value: '+30', id: 'GR' },
  { label: 'Greenland', value: '+299', id: 'GL' },
  { label: 'Grenada', value: '+1 473', id: 'GD' },
  { label: 'Guadeloupe', value: '+590', id: 'GP' },
  { label: 'Guam', value: '+1 671', id: 'GU' },
  { label: 'Guatemala', value: '+502', id: 'GT' },
  { label: 'Guinea', value: '+224', id: 'GN' },
  { label: 'Guinea-Bissau', value: '+245', id: 'GW' },
  { label: 'Guyana', value: '+595', id: 'GY' },
  { label: 'Haiti', value: '+509', id: 'HT' },
  { label: 'Honduras', value: '+504', id: 'HN' },
  { label: 'Hungary', value: '+36', id: 'HU' },
  { label: 'Iceland', value: '+354', id: 'IS' },
  { label: 'India', value: '+91', id: 'IN' },
  { label: 'Indonesia', value: '+62', id: 'ID' },
  { label: 'Iraq', value: '+964', id: 'IQ' },
  { label: 'Ireland', value: '+353', id: 'IE' },
  { label: 'Israel', value: '+972', id: 'IL' },
  { label: 'Italy', value: '+39', id: 'IT' },
  { label: 'Jamaica', value: '+1 876', id: 'JM' },
  { label: 'Japan', value: '+81', id: 'JP' },
  { label: 'Jordan', value: '+962', id: 'JO' },
  { label: 'Kazakhstan', value: '+7 7', id: 'KZ' },
  { label: 'Kenya', value: '+254', id: 'KE' },
  { label: 'Kiribati', value: '+686', id: 'KI' },
  { label: 'Kuwait', value: '+965', id: 'KW' },
  { label: 'Kyrgyzstan', value: '+996', id: 'KG' },
  { label: 'Latvia', value: '+371', id: 'LV' },
  { label: 'Lebanon', value: '+961', id: 'LB' },
  { label: 'Lesotho', value: '+266', id: 'LS' },
  { label: 'Liberia', value: '+231', id: 'LR' },
  { label: 'Liechtenstein', value: '+423', id: 'LI' },
  { label: 'Lithuania', value: '+370', id: 'LT' },
  { label: 'Luxembourg', value: '+352', id: 'LU' },
  { label: 'Madagascar', value: '+261', id: 'MG' },
  { label: 'Malawi', value: '+265', id: 'MW' },
  { label: 'Malaysia', value: '+60', id: 'MY' },
  { label: 'Maldives', value: '+960', id: 'MV' },
  { label: 'Mali', value: '+223', id: 'ML' },
  { label: 'Malta', value: '+356', id: 'MT' },
  { label: 'Marshall Islands', value: '+692', id: 'MH' },
  { label: 'Martinique', value: '+596', id: 'MQ' },
  { label: 'Mauritania', value: '+222', id: 'MR' },
  { label: 'Mauritius', value: '+230', id: 'MU' },
  { label: 'Mayotte', value: '+262', id: 'YT' },
  { label: 'Mexico', value: '+52', id: 'MX' },
  { label: 'Monaco', value: '+377', id: 'MC' },
  { label: 'Mongolia', value: '+976', id: 'MN' },
  { label: 'Montenegro', value: '+382', id: 'ME' },
  { label: 'Montserrat', value: '+1664', id: 'MS' },
  { label: 'Morocco', value: '+212', id: 'MA' },
  { label: 'Myanmar', value: '+95', id: 'MM' },
  { label: 'Namibia', value: '+264', id: 'NA' },
  { label: 'Nauru', value: '+674', id: 'NR' },
  { label: 'Nepal', value: '+977', id: 'NP' },
  { label: 'Netherlands', value: '+31', id: 'NL' },
  { label: 'Netherlands Antilles', value: '+599', id: 'AN' },
  { label: 'New Caledonia', value: '+687', id: 'NC' },
  { label: 'New Zealand', value: '+64', id: 'NZ' },
  { label: 'Nicaragua', value: '+505', id: 'NI' },
  { label: 'Niger', value: '+227', id: 'NE' },
  { label: 'Nigeria', value: '+234', id: 'NG' },
  { label: 'Niue', value: '+683', id: 'NU' },
  { label: 'Norfolk Island', value: '+672', id: 'NF' },
  { label: 'Northern Mariana Islands', value: '+1 670', id: 'MP' },
  { label: 'Norway', value: '+47', id: 'NO' },
  { label: 'Oman', value: '+968', id: 'OM' },
  { label: 'Pakistan', value: '+92', id: 'PK' },
  { label: 'Palau', value: '+680', id: 'PW' },
  { label: 'Panama', value: '+507', id: 'PA' },
  { label: 'Papua New Guinea', value: '+675', id: 'PG' },
  { label: 'Paraguay', value: '+595', id: 'PY' },
  { label: 'Peru', value: '+51', id: 'PE' },
  { label: 'Philippines', value: '+63', id: 'PH' },
  { label: 'Poland', value: '+48', id: 'PL' },
  { label: 'Portugal', value: '+351', id: 'PT' },
  { label: 'Puerto Rico', value: '+1 939', id: 'PR' },
  { label: 'Qatar', value: '+974', id: 'QA' },
  { label: 'Romania', value: '+40', id: 'RO' },
  { label: 'Rwanda', value: '+250', id: 'RW' },
  { label: 'Samoa', value: '+685', id: 'WS' },
  { label: 'San Marino', value: '+378', id: 'SM' },
  { label: 'Saudi Arabia', value: '+966', id: 'SA' },
  { label: 'Senegal', value: '+221', id: 'SN' },
  { label: 'Serbia', value: '+381', id: 'RS' },
  { label: 'Seychelles', value: '+248', id: 'SC' },
  { label: 'Sierra Leone', value: '+232', id: 'SL' },
  { label: 'Singapore', value: '+65', id: 'SG' },
  { label: 'Slovakia', value: '+421', id: 'SK' },
  { label: 'Slovenia', value: '+386', id: 'SI' },
  { label: 'Solomon Islands', value: '+677', id: 'SB' },
  { label: 'South Africa', value: '+27', id: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: '+500', id: 'GS' },
  { label: 'Spain', value: '+34', id: 'ES' },
  { label: 'Sri Lanka', value: '+94', id: 'LK' },
  { label: 'Sudan', value: '+249', id: 'SD' },
  { label: 'Surilabel', value: '+597', id: 'SR' },
  { label: 'Swaziland', value: '+268', id: 'SZ' },
  { label: 'Sweden', value: '+46', id: 'SE' },
  { label: 'Switzerland', value: '+41', id: 'CH' },
  { label: 'Tajikistan', value: '+992', id: 'TJ' },
  { label: 'Thailand', value: '+66', id: 'TH' },
  { label: 'Togo', value: '+228', id: 'TG' },
  { label: 'Tokelau', value: '+690', id: 'TK' },
  { label: 'Tonga', value: '+676', id: 'TO' },
  { label: 'Trinidad and Tobago', value: '+1 868', id: 'TT' },
  { label: 'Tunisia', value: '+216', id: 'TN' },
  { label: 'Turkey', value: '+90', id: 'TR' },
  { label: 'Turkmenistan', value: '+993', id: 'TM' },
  { label: 'Turks and Caicos Islands', value: '+1 649', id: 'TC' },
  { label: 'Tuvalu', value: '+688', id: 'TV' },
  { label: 'Uganda', value: '+256', id: 'UG' },
  { label: 'Ukraine', value: '+380', id: 'UA' },
  { label: 'United Arab Emirates', value: '+971', id: 'AE' },
  { label: 'United Kingdom', value: '+44', id: 'GB' },
  { label: 'United States', value: '+1', id: 'US' },
  { label: 'Uruguay', value: '+598', id: 'UY' },
  { label: 'Uzbekistan', value: '+998', id: 'UZ' },
  { label: 'Vanuatu', value: '+678', id: 'VU' },
  { label: 'Wallis and Futuna', value: '+681', id: 'WF' },
  { label: 'Yemen', value: '+967', id: 'YE' },
  { label: 'Zambia', value: '+260', id: 'ZM' },
  { label: 'Zimbabwe', value: '+263', id: 'ZW' },
  { label: 'Bolivia, Plurinational State of', value: '+591', id: 'BO' },
  { label: 'Brunei Darussalam', value: '+673', id: 'BN' },
  { label: 'Cocos (Keeling) Islands', value: '+61', id: 'CC' },
  { label: 'Congo, The Democratic Republic of the', value: '+243', id: 'CD' },
  { label: "Cote d'Ivoire", value: '+225', id: 'CI' },
  { label: 'Falkland Islands (Malvinas)', value: '+500', id: 'FK' },
  { label: 'Guernsey', value: '+44', id: 'GG' },
  { label: 'Holy See (Vatican City State)', value: '+379', id: 'VA' },
  { label: 'Hong Kong', value: '+852', id: 'HK' },
  { label: 'Iran, Islamic Republic of', value: '+98', id: 'IR' },
  { label: 'Isle of Man', value: '+44', id: 'IM' },
  { label: 'Jersey', value: '+44', id: 'JE' },
  { label: "Korea, Democratic People's Republic of", value: '+850', id: 'KP' },
  { label: 'Korea, Republic of', value: '+82', id: 'KR' },
  { label: "Lao People's Democratic Republic", value: '+856', id: 'LA' },
  { label: 'Libyan Arab Jamahiriya', value: '+218', id: 'LY' },
  { label: 'Macao', value: '+853', id: 'MO' },
  { label: 'Macedonia, The Former Yugoslav Republic of', value: '+389', id: 'MK' },
  { label: 'Micronesia, Federated States of', value: '+691', id: 'FM' },
  { label: 'Moldova, Republic of', value: '+373', id: 'MD' },
  { label: 'Mozambique', value: '+258', id: 'MZ' },
  { label: 'Palestinian Territory, Occupied', value: '+970', id: 'PS' },
  { label: 'Pitcairn', value: '+872', id: 'PN' },
  { label: 'Réunion', value: '+262', id: 'RE' },
  { label: 'Russia', value: '+7', id: 'RU' },
  { label: 'Saint Barthélemy', value: '+590', id: 'BL' },
  { label: 'Saint Helena, Ascension and Tristan Da Cunha', value: '+290', id: 'SH' },
  { label: 'Saint Kitts and Nevis', value: '+1 869', id: 'KN' },
  { label: 'Saint Lucia', value: '+1 758', id: 'LC' },
  { label: 'Saint Martin', value: '+590', id: 'MF' },
  { label: 'Saint Pierre and Miquelon', value: '+508', id: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: '+1 784', id: 'VC' },
  { label: 'Sao Tome and Principe', value: '+239', id: 'ST' },
  { label: 'Somalia', value: '+252', id: 'SO' },
  { label: 'Svalbard and Jan Mayen', value: '+47', id: 'SJ' },
  { label: 'Syrian Arab Republic', value: '+963', id: 'SY' },
  { label: 'Taiwan, Province of China', value: '+886', id: 'TW' },
  { label: 'Tanzania, United Republic of', value: '+255', id: 'TZ' },
  { label: 'Timor-Leste', value: '+670', id: 'TL' },
  { label: 'Venezuela, Bolivarian Republic of', value: '+58', id: 'VE' },
  { label: 'Viet Nam', value: '+84', id: 'VN' },
  { label: 'Virgin Islands, British', value: '+1 284', id: 'VG' },
  { label: 'Virgin Islands, U.S.', value: '+1 340', id: 'VI' },
];
