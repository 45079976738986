import { IYard } from 'containers/Administration/Yards/type';
import { GET_ALL_YARD_SUCCESS, GET_LIST_YARD_SUCCESS } from './action';
import { CrudAction } from './types';

export type YardType = {
  yards: IYard[];
  detailYard: IYard;
  yardAll: IYard[];
};

const defaultState: YardType = {
  yards: [],
  detailYard: null,
  yardAll: [],
};

export default function yardReducer(state = defaultState, action: CrudAction) {
  switch (action.type) {
    case GET_LIST_YARD_SUCCESS:
      return {
        ...state,
        yards: action.payload.yards,
      };
    case GET_ALL_YARD_SUCCESS:
      return {
        ...state,
        yardAll: action.payload.yardAll,
      };
    default:
      return state;
  }
}
