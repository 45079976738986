import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label } from 'reactstrap';
import { Input, Select } from 'components/form';
import { OWNER_OF_PHONE_NUMBER } from 'constant';
import { MOBILE_COUNTRY_CODE } from 'constant/countrycode';
import { useFormikContext } from 'formik';

const ReceiptRecordPayment = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<any>();

  return (
    <div className="px-4">
      <FormGroup row>
        <Col lg={6}>
          <Label>{t('Transaction/Receipt Number')}</Label>
          <Input id="receipt_number" placeholder={t('Type in transaction/receipt number')} />
        </Col>
      </FormGroup>
    </div>
  );
};

export default ReceiptRecordPayment;
