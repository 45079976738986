/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useField, useFormikContext } from 'formik';
import { Label, FormGroup } from 'reactstrap';
import styled from 'styled-components';
import { useTranslation, Translation } from 'react-i18next';

interface TvsSelectProps {
  options: any[];
  id: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  styles?: any;
  handleChange?: (x: any) => void;
}
const TvsSelect = ({ options, id, label, placeholder, disabled, loading, handleChange, styles }: TvsSelectProps) => {
  const [metadata] = useField(id);
  const formikContext = useFormikContext<any>();
  const { errors, touched, values, setValues, isSubmitting } = formikContext;
  const [optionList, setOptionList] = useState<any>([]);

  const [inputTouched, setTouched] = useState(touched[id] || false);
  const inputErrors = errors[id] || false;

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true);
    }
  }, [isSubmitting]);

  useEffect(() => {
    // setOptionList(options.map((item) => (typeof item === 'string' ? { value: item, label: item } : item)));
    setOptionList(
      options.map((item) =>
        typeof item === 'string'
          ? { value: item, label: <Translation>{(t, { i18n }) => <span>{t(item)}</span>}</Translation> }
          : item,
      ),
    );
  }, [options]);

  const onChange = (value: any) => {
    setValues({
      ...values,
      [id]: value.value,
    });
  };

  const customStyles = {
    ...styles,
    menu: () => ({
      minWidth: '300px',
      position: 'absolute',
      zIndex: 100,
      background: '#fff',
    }),
    control: () => ({
      background: disabled ? '#e5e3e3' : '#fff',
      display: 'flex',
    }),
    valueContainer: () => ({
      display: 'flex',
      paddingLeft: '10px',
      paddingRight: '10px',
      width: '90%',
      overflow: 'hidden',
      position: 'relative',
    }),
  };

  return (
    <FormGroup className="react-select">
      {label && <Label>{label}</Label>}
      <SelectStyle
        id={id}
        options={optionList}
        value={metadata.value ? optionList.filter((opt: any) => opt?.value === metadata.value) : ''}
        onChange={handleChange || onChange}
        name={id}
        styles={customStyles}
        placeholder={placeholder}
        isDisabled={disabled || loading}
        isLoading={loading}
      />

      {inputTouched && inputErrors && (
        <div className="text-danger form-text text-small">{inputTouched && inputErrors}</div>
      )}
    </FormGroup>
  );
};

const SelectStyle = styled(Select)`
  > div {
    min-height: 50px;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    div[class*='indicatorContainer'] {
      padding-left: 0;
    }
    span[class*='indicatorSeparator'] {
      display: none;
    }
    div[class*='-menu'] {
      width: 400px;
      z-index: 100;
    }
    &:hover {
      border: 1px solid #80bdff;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
  }
`;
export default TvsSelect;
