/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import TimeIdle from 'components/elements/IdleTime';
import { Header } from 'components/layouts';
import { IDLE_TIMEOUT } from 'constant';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Container, Modal, ModalBody, ModalFooter, ListGroup, ListGroupItem } from 'reactstrap';
import { userLogout } from 'redux/auth/actions';
import styled from 'styled-components';
import './dealer.scss';
import useScreenSize from 'utils/screenSize';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

type SuperuserTemplateProps = {
  children?: FC;
};

const SuperuserTemplate: FC<SuperuserTemplateProps> = ({ children }) => {
  const size = useScreenSize();
  const [isTimeout, setIsTimeout] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (size.screen === 'l' || size.screen === 'xl') {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
  }, [size]);

  useEffect(() => {
    const timer = new TimeIdle({
      timeout: IDLE_TIMEOUT,
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpire: () => {
        handleTimeout();
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  const handleTimeout = () => {
    history.push('/login?');
    dispatch(userLogout());
    setIsTimeout(false);
  };

  const menus = [
    {
      name: 'Broadcast',
      items: [
        {
          name: 'Add message',
          href: '/broadcast/add',
          link: '/broadcast/add',
        },
        {
          name: 'View messages',
          href: '/broadcast/view',
          link: '/broadcast/view',
        },
      ],
    },
  ];

  const renderNavMenu = (el: any) => {
    return (
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {el?.icon && <img src={`${process.env.PUBLIC_URL}/images/${el.icon}`} alt="icon" />}
          {!el?.link ? (
            <div className="ml-3">{el.name}</div>
          ) : (
            <Button
              color="text"
              onClick={() => {
                if (['md', 's', 'xs'].includes(size.screen)) setShowSidebar(false);
                history.push(el.link);
              }}
            >
              {el.name}
            </Button>
          )}
        </div>
        {el?.items && <div className="table-arrow-down" />}
      </div>
    );
  };

  const handleLogout = () => {
    dispatch(userLogout());
    history.push('/login');
  };

  const route = useRouteMatch();

  const activeRoute = route.path === '/' ? '/broadcast/add' : route.path;

  return (
    <Main>
      <Modal isOpen={isTimeout} centered>
        <ModalBody>
          <div>{t('Your session is expired. Please login again')}</div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleTimeout()} color="primary border-0">
            {t('Ok')}
          </Button>
        </ModalFooter>
      </Modal>
      <Header
        onClickMenu={() => {
          window.scrollTo(0, 0);
          setShowSidebar(!showSidebar);
        }}
      />
      <Container style={{ marginTop: '49px', paddingLeft: 0, paddingRight: 0 }} fluid>
        <div className="d-flex h-100">
          {showSidebar && (
            <Sidebar className="p-0 d-flex flex-column justify-content-between">
              <ListGroupStyle>
                {menus.map((el: any) => (
                  <ListGroupItem tag={el.href ? 'a' : 'div'} href={el?.href} key={el.name} className="parent is-active">
                    {renderNavMenu(el)}
                    {el?.items && (
                      <ListGroupStyle>
                        {el?.items?.map((child: any, index: number) => (
                          <ListGroupItem
                            key={child.href}
                            className={`sub ${activeRoute.includes(child.link) ? 'is-active' : ''}`}
                          >
                            {renderNavMenu(child)}
                          </ListGroupItem>
                        ))}
                      </ListGroupStyle>
                    )}
                  </ListGroupItem>
                ))}
              </ListGroupStyle>
              <ListGroupStyle>
                <div className="text-center">
                  <img src={`${process.env.PUBLIC_URL}/images/footer.png`} alt="powered by tvs" />
                </div>
              </ListGroupStyle>
            </Sidebar>
          )}
          <Content>
            <div>{children}</div>
          </Content>
        </div>
      </Container>
    </Main>
  );
};
const Main = styled.div`
  background-color: #faf8fe;
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
`;
const Sidebar = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  width: 200px;
  position: fixed;
  height: calc(100vh - 50px);
  @media (max-width: 820px) {
    position: static;
    width: 100%;
    flex: 0 0 100%;
  }
`;
const Content = styled.div`
  flex: 1;
  margin-top: 5px;
  margin-left: 200px;
  @media (max-width: 820px) {
    margin-left: 0;
  }
  @media (max-width: 414px) {
    padding: 0;
  }
`;
const ListGroupStyle = styled(ListGroup)`
  margin-top: 20px;
  .list-group {
    margin: 0;
  }
  .list-group-item {
    background-color: transparent;
    padding: 0;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 1px solid transparent;
    margin-left: -1px;
    &.sub {
      border-bottom: 1px solid #e6e6e6;
      padding-left: 27px;
      &.is-active {
        a,
        .btn {
          font-weight: 500;
          color: #5f56a6;
        }
      }
    }
    > div {
      padding: 15px;
    }
    &.parent.is-active {
      > div {
        border-left: 4px solid #5f56a6;
        background-color: #f5f3ff;
      }
    }
  }
`;

export default SuperuserTemplate;
