/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { useQuery } from 'containers/Payment/record-payment';

interface Props {
  handleChange: (x: string) => void;
  data: string[];
}

const CsDropDown = ({ data, handleChange }: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [isCheckQuery, setIsCheckQuery] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [active, setActive] = useState<any>('All');
  const query = useQuery();
  const { t } = useTranslation();

  useEffect(() => {
    const agent = query.get('agent');
    if (agent && !isCheckQuery) {
      const finding = data?.find((item: any) => +item.agent_id === +agent);
      if (finding) {
        setActive(finding);
        setIsCheckQuery(true);
      }
    }
  }, [query]);

  const handleSelectItem = (item: string) => {
    setActive(item);
    handleChange(item);
  };

  const renderItem = (item: any) => {
    return (
      <DropdownItem key={item.agent_id} onClick={() => handleSelectItem(item)}>
        {t(item.full_name)}
      </DropdownItem>
    );
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggleStyle caret color="x" className="p-0 border-0">
        {active === 'All' ? t('All CSs') : t(active.full_name)}
      </DropdownToggleStyle>
      <DropdownMenu className="mt-2">
        <DropdownItem key="All" onClick={() => handleSelectItem('All')}>
          {t('All CSs')}
        </DropdownItem>
        {data?.map((item) => renderItem(item))}
      </DropdownMenu>
    </Dropdown>
  );
};

export const DropdownToggleStyle = styled(DropdownToggle)`
  &.btn {
    color: #263238;
    font-size: 20px;
    font-weight: 500;
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }
  }
`;

export default CsDropDown;
