export default class TimeIdle {
  timeout: any;

  onTimeout: any;

  eventHandler: () => void;

  interval: any;

  timeoutTracker: any;

  constructor({ timeout, onTimeout, onExpire }: any) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;

    const expireTime = localStorage.getItem('_expireTime');

    if (expireTime && +expireTime > 0 && parseInt(expireTime, 10) < Date.now()) {
      onExpire();
    }
    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval = () => {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expireTime = localStorage.getItem('_expireTime');
      if (expireTime && parseInt(expireTime, 10) < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, this.timeout);
  };

  updateExpiredTime = () => {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      const token = localStorage.getItem('tvs.token');
      if (token) localStorage.setItem('_expireTime', JSON.stringify(Date.now() + this.timeout * 1000));
    });
  };

  cleanUp = () => {
    localStorage.removeItem('_expireTime');
    window.clearInterval(this.interval);
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  };

  tracker = () => {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
  };
}
