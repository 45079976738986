import AppAPIInstance from 'utils/requestVinova';
import { QueryBook } from 'containers/Administration/Books/type';
import { StateHistory } from 'redux/types';

import { API } from '../constans';

// GET LIST BOOK
export const getListBook = async (payload: QueryBook): Promise<any> => {
  return AppAPIInstance.get(API.ADMINISTRATION.BOOK.LIST, { params: payload });
};

// GET DETAIL BOOK
export const getDetailBook = async (id: string): Promise<any> => {
  return AppAPIInstance.get(API.ADMINISTRATION.BOOK.DETAIL.replace(':id', id));
};

// GET LIST USER
export const getListUserID = async (): Promise<any> => {
  return AppAPIInstance.get(API.ADMINISTRATION.BOOK.ID_USER);
};

// PUT DETAIL BOOK
export const putDetailBook = async (payload, id): Promise<any> => {
  return AppAPIInstance.put(API.ADMINISTRATION.BOOK.PUT_BOOK.replace(':id', id), payload);
};

// CREATE BOOK
export const createBook = async (payload): Promise<any> => {
  return AppAPIInstance.post(API.ADMINISTRATION.BOOK.CREATE_BOOK, payload);
};

// BULK UPLOAD BOOK
export const uploadBook = async (payload): Promise<any> => {
  return AppAPIInstance.post(API.ADMINISTRATION.BOOK.UPLOAD, { file_uploaded_url: payload });
};

// BULK UPLOAD BOOK
export const sendMappingHeaders = async (id, payload): Promise<any> => {
  return AppAPIInstance.post(API.ADMINISTRATION.BOOK.UPDATE_MAPPING(id), payload);
};

// BULK UPLOAD BOOK HISTORY
export const getBookHistory = async (payload: StateHistory): Promise<any> => {
  return AppAPIInstance.get(API.ADMINISTRATION.BOOK.HISTORY, { params: payload });
};
