import React from 'react';

function BluePlus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      version="1"
      viewBox="0 0 346 334"
      fill="rgb(101, 89, 180)"
    >
      <path
        d="M1572 3068c-605-61-1102-538-1198-1148-17-112-12-359 10-465 58-274 179-499 375-696 156-157 317-257 528-329 157-54 231-65 438-65 173 1 200 3 297 28 404 101 716 343 903 700 343 656 83 1482-574 1825-236 124-512 177-779 150zm215-688c65-39 68-51 71-296l3-223 223-3c194-3 225-5 249-21 88-58 88-180 1-238-26-17-50-19-251-19h-223v-212c0-188-3-218-19-255-37-79-131-103-204-51-51 37-57 66-57 303v215h-224c-216 0-224 1-257 23-85 57-83 166 5 231 25 20 44 21 252 24l224 3v444l31 39c18 21 41 42 53 46 36 15 90 11 123-10z"
        transform="matrix(.1 0 0 -.1 0 334)"
      />
    </svg>
  );
}

export default BluePlus;
