/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authReducer from 'redux/auth/reducer';
import { History } from 'history';
import crudReducer from 'redux/crud/reducer';

import { reducer as toastrReducer } from 'react-redux-toastr';
import assetsReducer from './assets/reducer';
import applicationReducer from './application/reducer';
import yardReducer from './yard/reducer';
import bidderReducer from './bidders/reducer';
import agencyUserReducer from './agencyUser/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

const rootReducer = (history: History<any>, injectedReducers = {}) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    crudState: crudReducer,
    toastr: toastrReducer,
    assets: assetsReducer,
    application: applicationReducer,
    yard: yardReducer,
    bidders: bidderReducer,
    agencyUser: agencyUserReducer,
    ...injectedReducers,
  });

export default rootReducer;
