/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label } from 'reactstrap';
import { useFormikContext } from 'formik';
import { Datepicker, Input, Select } from 'components/form';
import { ADDRESS_TYPE, PARTIAL_PAYMENT, PROMISE_TO_PAY, PTP_LOCATION } from 'constant';
import { addDays } from 'date-fns';
import { RecordPaymentContext } from 'helpers';
import AddressGroupField from './pmt-address';

const PtpRecordPayment = ({ custLocation }) => {
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext<any>();
  const { currency } = useContext(RecordPaymentContext);

  useEffect(() => {
    if (values.unable_pay_more) {
      const updatedValue = { ...values };
      updatedValue.ptp_date = '';
      updatedValue.ptp_location = '';
      updatedValue.ptp_amount = '';
      updatedValue.zipcode = '';
      updatedValue.city = '';
      updatedValue.province = '';
      updatedValue.is_ptp = false;
      updatedValue.cust_address2_type = '';
      updatedValue.cust_address2 = '';
      updatedValue.ptp_collection_address = '';
      setValues(updatedValue);
    }
  }, [values.unable_pay_more]);

  useEffect(() => {
    if (values.ptp_location !== 'Field') {
      setValues({
        ...values,
        zipcode: '',
        city: '',
        province: '',
        cust_address2: '',
        ptp_collection_address: '',
      });
    } else {
      const city = custLocation.city ? { city: custLocation.city } : {};
      const province = custLocation.province ? { province: custLocation.province } : {};
      setValues({
        ...values,
        ...city,
        ...province,
        zipcode: custLocation.zipcode,
        cust_address2: custLocation.address,
      });
    }
  }, [values.ptp_location]);

  const isShowPtp =
    !values.unable_pay_more && (values.payment_note === PARTIAL_PAYMENT || values.payment_note === PROMISE_TO_PAY);

  return (
    <div className="py-4">
      <div className="mx-4 mb-2 d-flex align-item-center">
        <Label>
          {t('Customer is')} <span style={{ fontWeight: 500 }}>{t('NOT able')}</span> {t('to pay further this month')}
        </Label>
        <Input
          type="switch"
          id="unable_pay_more"
          placeholder="Type amount"
          labelStyle={{ width: '50%', marginBottom: 0 }}
          groupStyle={{
            padding: '0 1.5rem',
            marginBottom: 0,
          }}
          inline
        />
      </div>
      {isShowPtp && (
        <div>
          <FormGroup className="px-4 mb-0" row>
            <Col lg={6} className="mb-3">
              <Input
                label={t('PTP Amount')}
                id="ptp_amount"
                placeholder={t('Type amount')}
                formatType="currency"
                inputIcon={<div>{currency}</div>}
                disabled={values.unable_pay_more}
              />
            </Col>
            <Col lg={6} className="mb-3">
              <Datepicker
                id="ptp_date"
                placeholder={t('Select Date')}
                label={t('PTP Date')}
                minDate={addDays(new Date(), 1)}
                disabled={values.unable_pay_more}
              />
            </Col>
            <Col lg={6} className="mb-3">
              <Select
                label={t('PTP Location')}
                placeholder={t('Select...')}
                id="ptp_location"
                options={PTP_LOCATION}
                disabled={values.unable_pay_more}
              />
            </Col>
            {values?.ptp_location === 'Field' && (
              <>
                <Col lg={6} className="mb-3">
                  <Select
                    label={t('Address Type')}
                    placeholder={t('Select...')}
                    id="cust_address2_type"
                    options={ADDRESS_TYPE.map(({ label }) => t(label))}
                    disabled={values.unable_pay_more}
                  />
                </Col>
                <AddressGroupField disabled={values.unable_pay_more} />
                <Col lg={6}>
                  <Input type="textarea" id="cust_address2" label={t('Address')} disabled={values.unable_pay_more} />
                </Col>
              </>
            )}
            {/* {values?.ptp_location === 'Field' && (
              <Col lg={6}>
                <Input
                  type="textarea"
                  label="Collection Address"
                  id="ptp_collection_address"
                  placeholder="Type address"
                  disabled={values.unable_pay_more}
                />
              </Col>
            )} */}
          </FormGroup>
        </div>
      )}
    </div>
  );
};

export default PtpRecordPayment;
