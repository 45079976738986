/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import TimeIdle from 'components/elements/IdleTime';
import { Header, Sidebar } from 'components/layouts';
import { IDLE_TIMEOUT } from 'constant';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Container, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { userLogout } from 'redux/auth/actions';
import { clearActionStatus } from 'redux/crud/action';
import styled from 'styled-components';
import './dashboard.scss';
import useScreenSize from 'utils/screenSize';

type DashboardTemplateProps = {
  // children?: FC;
};

const DashboardTemplate: FC<DashboardTemplateProps> = ({ children }) => {
  const { t } = useTranslation();
  const size = useScreenSize();
  const [isTimeout, setIsTimeout] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (size.screen === 'l' || size.screen === 'xl') {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
  }, [size]);

  useEffect(() => {
    const timer = new TimeIdle({
      timeout: IDLE_TIMEOUT,
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpire: () => {
        dispatch(userLogout());
        dispatch(clearActionStatus('userTc'));
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  const handleTimeout = () => {
    dispatch(userLogout());
    setIsTimeout(false);
  };

  return (
    <Main>
      <Modal isOpen={isTimeout} centered>
        <ModalBody>
          <div>{t('Your session is expired. Please login again')}</div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleTimeout()} color="primary border-0">
            {t('Ok')}
          </Button>
        </ModalFooter>
      </Modal>
      <Header
        onClickMenu={() => {
          window.scrollTo(0, 0);
          setShowSidebar(!showSidebar);
        }}
      />
      <Container style={{ marginTop: '49px', paddingLeft: 0, paddingRight: 0 }} fluid>
        <div className="d-flex h-100">
          {showSidebar && <Sidebar size={size} setShowSidebar={setShowSidebar} />}
          <Content>
            <div>{children}</div>
          </Content>
        </div>
      </Container>
    </Main>
  );
};

const Main = styled.div`
  background-color: #f5f5f5;
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
`;

const Content = styled.div`
  flex: 1;
  margin-top: 5px;
  margin-left: 260px;
  @media (max-width: 990px) {
    margin-left: 0;
  }
  @media (max-width: 414px) {
    padding: 0;
  }
`;

export default DashboardTemplate;
